import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";
import { setItemInLocalStorage } from "core/utils/use-local-storage";

export const patientDashboardLastRouteKey = "last-patient-dashboard-route";

export default class BioResultsSummaryRoute extends Route {
  @service declare store: Store;
  @service declare fetcher: FetcherService;

  async model() {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    await Promise.all([
      this.fetcher.loadRecords("bio-exam-results-received-journal-event", {
        filter: { patient_id: patient.id },
        include: [
          "creator",
          "file-attachments",
          "apicrypt-message-received-journal-events"
        ].join(",")
      }),
      this.fetcher.loadRecords("loinc", {
        filter: { patient_id: patient.id }
      }),
      this.fetcher.loadRecords(
        "bio-exam-expected-dosage-changed-journal-event",
        {
          filter: { patient_id: patient.id }
        }
      )
    ]);

    return patient;
  }

  activate() {
    setItemInLocalStorage(
      patientDashboardLastRouteKey,
      "medics-web.protected.patients.patient.dashboard.bio-results.summary"
    );
  }
}
