import AuthUtils from "core/utils/authentication";
import BaseAuthenticator, { AuthData } from "./base";

export default class MedicsPasswordAuthenticator extends BaseAuthenticator<AuthData> {
  loginEndpoint = "/api/v1/medic_login";

  async authenticate({
    email,
    password,
    twoFactorCode,
    twoFactorToken,
    browserAuthToken
  }: {
    email: string;
    password: string;
    twoFactorCode?: string;
    twoFactorToken?: string;
    browserAuthToken?: string;
  }): Promise<AuthData | undefined> {
    let data;

    try {
      const response = await this.sendLoginRequest({
        email,
        password,
        two_factor_code: twoFactorCode,
        two_factor_token: twoFactorToken,
        browser_auth_token: browserAuthToken
      });

      data = {
        as: "medic",
        id: response.id,
        uuid: response.uuid,
        role: response.role,
        access_token: response.access_token,
        refresh_token: response.refresh_token
      };

      const newTwoFactorToken = response.two_factor_auth_token;
      if (newTwoFactorToken) {
        AuthUtils.save2faTokenInStorage(email, newTwoFactorToken);
      }

      const newBrowserConnectionAuthToken = response.browser_auth_token;
      if (newBrowserConnectionAuthToken) {
        AuthUtils.saveBrowserAuthToken(email, newBrowserConnectionAuthToken);
      }
    } catch (e) {
      throw {
        reason: e
      };
    }

    return this.onAuthenticationFinished(data, "Medic");
  }
}
