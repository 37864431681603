import Controller from "@ember/controller";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class LoginController extends Controller {
  queryParams = ["code"];
  @tracked code: string | undefined;

  @action
  resetCode() {
    // Reset query params to not start loading PSC with an old auth code
    this.code = undefined;
  }
}
