import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouteInfo from "@ember/routing/route-info";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import Patient from "core/models/patient";

export default class PreTreatmentSynthesisRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  beforeModel(transition: Transition) {
    const queryParams = (transition.to as RouteInfo).queryParams;
    if (
      (!queryParams.treatmentId || !queryParams.protocolUuid) &&
      !queryParams.snapshotCapturedId
    ) {
      console.error("Missing query parameters to enter this route");
      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard"
      );
    }
  }

  async model() {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    return patient;
  }
}
