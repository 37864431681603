import { getOwner } from "@ember/-internals/owner";
import Route from "@ember/routing/route";
import RouteInfo from "@ember/routing/route-info";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import PasswordValidations from "core/validations/password";
import { Changeset, EmberChangeset } from "ember-changeset";
import lookupValidator from "ember-changeset-validations";
import { BufferedChangeset } from "ember-changeset/types";
import fetch from "fetch";
import ApplicationAdapter from "parog-web/adapters/application";
import emberSwal from "ui-kit/utils/ember-swal";

interface PasswordModel {
  password: string | null;
  passwordConfirmation: string | null;
}

export default class SetPasswordRoute extends Route<BufferedChangeset> {
  @service declare router: RouterService;

  token: string | undefined;
  checkResetPasswordTokenEndPoint = "/api/v1/check_reset_password_token";
  applicationAdapter = getOwner(this)?.lookup(
    "adapter:application"
  ) as ApplicationAdapter;

  async beforeModel(transition: Transition): Promise<void> {
    const queryParams = (transition.to as RouteInfo).queryParams;
    if (queryParams.token) {
      this.token = queryParams.token as string;
      await this.checkResetPasswordToken();
    } else {
      this.router.transitionTo("medics-web.login");
    }
  }

  model(): EmberChangeset {
    const model: PasswordModel = {
      password: null,
      passwordConfirmation: null
    };
    return Changeset(
      model,
      lookupValidator(PasswordValidations),
      PasswordValidations
    );
  }

  private async checkResetPasswordToken(): Promise<void> {
    const token = this.token;
    const data = JSON.stringify({
      token,
      auth: {
        user_type: "medic"
      }
    });

    await fetch(this.checkResetPasswordTokenEndPoint, {
      method: "POST",
      headers: Object.assign(
        {
          "Content-Type": "application/json"
        },
        this.applicationAdapter.headers
      ),
      body: data
    }).then(async response => {
      if (!response.ok) {
        emberSwal.fire({
          title: "Oups, votre lien est expiré",
          text: "Merci de bien vouloir renouveler celui-ci.",
          icon: "error"
        });
        this.router.transitionTo(
          "medics-web.forgot-password.send-instructions"
        );
      }
    });
  }
}
