import Store from "@ember-data/store";
import type ModelRegistry from "@ember-data/types/registries/model";
import { service } from "@ember/service";
import AlertTriggeredJournalEvent from "core/models/alert-triggered-journal-event";
import NotificationTriggeredJournalEvent from "core/models/notification-triggered-journal-event";
import TreatmentHistoryChangedJournalEvent from "core/models/treatment-history-changed-journal-event";
import FetcherService from "core/services/fetcher";
import { SemeiaMedicSession } from "core/services/session";
import PatientListFilterSerializer from "core/utils/patient-list-filter-serializer";
import { ModelFrom } from "parog-web";
import { hash } from "rsvp";
import { ApiFilterRegistry } from "semeia-api";
import MedicsWebIndexTabRoute, {
  MedicsWebTabModel,
  MedicsWebTabParams
} from "../tab/route";

interface Model extends MedicsWebTabModel {
  filter: PatientListFilterSerializer;
  patients: ModelFrom<MedicsWebTabModel>["patients"];
  alerts: Array<AlertTriggeredJournalEvent>;
  trialPeriodToValidateCount: number;
  eventsForNotifications: Array<NotificationTriggeredJournalEvent>;
  treatments: Array<TreatmentHistoryChangedJournalEvent>;
}

export default class MedicsWebIndexContentRoute extends MedicsWebIndexTabRoute {
  @service declare store: Store;
  @service declare fetcher: FetcherService;
  @service declare session: SemeiaMedicSession;

  get tabType() {
    return "alerts-and-notifications";
  }

  async model(params: MedicsWebTabParams): Promise<Model> {
    const model = await super.model(params);

    const patientIds = new Set(model.patients.map(p => p.id));

    await this.loadAlertsAndNotifications(model.filter);

    const tlsPrescribedForTrialperiodNotValidated = await this.store.query(
      "tls-prescribed-journal-event",
      {
        filter: Object.assign(model.filter.journalEventsApiFilter, {
          trial_period_must_be_validated: true
        })
      }
    );

    return Object.assign(model, {
      alerts: this.store
        .peekAll("alert-triggered-journal-event")
        .filter(je => patientIds.has(je.patient.id)),
      trialPeriodToValidateCount:
        tlsPrescribedForTrialperiodNotValidated.length,
      eventsForNotifications: this.store
        .peekAll("notification-triggered-journal-event")
        .filter(je => patientIds.has(je.patient.id)),
      treatments: this.store
        .peekAll("treatment-history-changed-journal-event")
        .filter(je => patientIds.has(je.patient.id))
    });
  }

  private async loadAlertsAndNotifications(
    filter: PatientListFilterSerializer
  ): Promise<{
    alerts: AlertTriggeredJournalEvent[];
    notifications: NotificationTriggeredJournalEvent[];
    treatments: TreatmentHistoryChangedJournalEvent[];
  }> {
    const alerts = this.loadJournalEventRecords(
      "alert-triggered-journal-event",
      filter,
      { non_processed: "", for_current_medic: "" },
      [
        "triggering-journal-event",
        "comment-written-journal-events",
        "comment-written-journal-events.creator",
        "alert-ongoing-journal-event",
        "alert-ongoing-journal-event.creator"
      ].join(",")
    );

    const notifications = this.loadJournalEventRecords(
      "notification-triggered-journal-event",
      filter,
      { non_processed: "", for_current_medic: "" }
    );

    const treatments = this.loadJournalEventRecords(
      "treatment-history-changed-journal-event",
      filter,
      { validation_for_ok_chimio: "" },
      "ok-chimio-snapshot-captured-journal-events"
    );

    return hash({ alerts, notifications, treatments });
  }

  private loadJournalEventRecords<T extends keyof ModelRegistry>(
    type: T,
    filter: PatientListFilterSerializer,
    specificFilter?: ApiFilterRegistry[T],
    include?: string
  ): Promise<ModelRegistry[T][]> {
    const query: Record<string, unknown> = {
      filter: Object.assign({}, filter.journalEventsApiFilter, specificFilter)
    };

    if (include) {
      query.include = include;
    }

    return this.store.query(type, query) as unknown as Promise<
      ModelRegistry[T][]
    >;
  }
}
