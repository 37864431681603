import Controller from "@ember/controller";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import SemeiaSessionService from "core/services/session";

export default class MedicsWebProtectedController extends Controller {
  @service declare session: SemeiaSessionService;

  @tracked isPasswordModalShown = this._shouldShowPasswordModal;

  private get _shouldShowPasswordModal(): boolean {
    return Boolean(
      this.session.authenticatedAtThisSession &&
        this.session.medic?.shouldChangePassword
    );
  }
}
