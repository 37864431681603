import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import InsRetrievedJournalEvent from "cnam/models/ins-retrieved-journal-event";
import InsAccessedJournalEvent from "core/models/ins-accessed-journal-event";
import Patient from "core/models/patient";
import PatientAdministrativeDataUpdatedJournalEvent from "core/models/patient-administrative-data-updated-journal-event";
import PatientEnrolledJournalEvent from "core/models/patient-enrolled-journal-event";
import { SemeiaMedicSession } from "core/services/session";
import { sortBy } from "core/utils/sort";
import { hash } from "rsvp";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from "ember-data";

export type PatientIdentityAccessLogsEvent =
  | InsAccessedJournalEvent
  | InsRetrievedJournalEvent
  | PatientAdministrativeDataUpdatedJournalEvent
  | PatientEnrolledJournalEvent;

interface Model {
  patient: Patient;
  events: Array<PatientIdentityAccessLogsEvent>;
}

export default class PatientIdentityAccessLogsRoute extends Route<Model> {
  @service declare router: RouterService;
  @service declare session: SemeiaMedicSession;
  @service declare store: Store;

  beforeModel() {
    if (this.session.medic.insAccessDisabled) {
      console.error(
        "user has access to INS disabled, they cannot access this route."
      );
      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard"
      );
    }
  }

  async model() {
    const fetchedModel = await this.fetchContent();
    let result: Model["events"] = [];

    result = result
      .concat(
        fetchedModel.insAccessedJournalEvents as unknown as Array<InsAccessedJournalEvent>
      )
      .concat(
        fetchedModel.insRetrievedJournalEvents as unknown as InsRetrievedJournalEvent[]
      )
      .concat(
        fetchedModel.patientAdministrativeDataUpdatedJournalEvents as unknown as PatientAdministrativeDataUpdatedJournalEvent[]
      );

    if (this.patient.patientEnrolledJournalEvent) {
      result.push(this.patient.patientEnrolledJournalEvent);
    }

    return {
      patient: this.patient,
      events: result.toSorted(sortBy("occurredAt", "desc"))
    };
  }

  private get patient(): Patient {
    return this.modelFor("medics-web.protected.patients.patient") as Patient;
  }

  private get fetchOptions() {
    return {
      filter: {
        patient_id: this.patient.id
      },
      include: "creator"
    };
  }

  private fetchContent() {
    const insAccessed = this.store.query(
      "ins-accessed-journal-event",
      this.fetchOptions
    );
    const insRetrieved = this.store.query(
      "ins-retrieved-journal-event",
      this.fetchOptions
    );
    const patientUpdated = this.store.query(
      "patient-administrative-data-updated-journal-event",
      this.fetchOptions
    );

    let patientEnrolled:
      | DS.PromiseObject<PatientEnrolledJournalEvent>
      | Promise<undefined>;
    if (this.patient.patientEnrolledJournalEvent) {
      patientEnrolled = this.store.findRecord(
        "patient-enrolled-journal-event",
        this.patient.patientEnrolledJournalEvent.id,
        Object.assign({ reload: true }, this.fetchOptions)
      );
    } else {
      patientEnrolled = Promise.resolve(undefined);
    }

    return hash({
      insAccessedJournalEvents: insAccessed,
      insRetrievedJournalEvents: insRetrieved,
      patientAdministrativeDataUpdatedJournalEvents: patientUpdated,
      patientEnrolledJournalEvent: patientEnrolled
    });
  }
}
