import BaseAuthenticator, { AuthData } from "./base";

interface PscAuthArgs {
  authorizationCode: string;
}

interface PscAuthData extends AuthData {
  psc_id_token: string;
}

export default class ProSanteConnectAuthenticator extends BaseAuthenticator<PscAuthData> {
  loginEndpoint = "/api/v1/medic_login";

  async authenticate({
    authorizationCode
  }: PscAuthArgs): Promise<PscAuthData | undefined> {
    let data;
    try {
      const response = await this.sendLoginRequest({
        psc_authorization_code: authorizationCode
      });

      data = {
        as: "medic",
        id: response.id,
        uuid: response.uuid,
        role: response.role,
        access_token: response.access_token,
        psc_id_token: response.psc_id_token
      };
    } catch (response) {
      throw {
        reason: response
      };
    }

    return this.onAuthenticationFinished(data, "Medic");
  }
}
