import { action } from "@ember/object";
import { service } from "@ember/service";
import BaseControllerComponent from "core/components/forc/abstract/controller/base-controller-component";
import FormAction from "core/components/forc/form-action";
import { ModelFrom } from "parog-web";
import type Route from "./route";

export default class FeatureComponentEditionController extends BaseControllerComponent {
  @service router;
  @service navigation;
  callbackUrl?: string;
  declare model: ModelFrom<Route>;

  @action
  transitionToDashboard(): void {
    if (this.callbackUrl) {
      this.router.transitionTo(this.callbackUrl);
    } else {
      this.router.transitionTo(
        `${this.navigation.currentPatientRouteName}.index`
      );
    }
  }

  get formAction(): FormAction {
    return FormAction.Edit;
  }

  get sourceJeId(): string {
    return this.model.sourceJeId;
  }
}
