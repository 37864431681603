import Route from "@ember/routing/route";
import RouteInfo from "@ember/routing/route-info";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import OnlineConsultationService from "core/services/online-consultation";

export default class PatientWebOnlineConsultationRoute extends Route {
  @service declare onlineConsultation: OnlineConsultationService;
  @service declare router: RouterService;

  beforeModel(transition: Transition) {
    const queryParams = (transition.to as RouteInfo).queryParams;

    if (!queryParams.consultation_token) {
      this.router.transitionTo("patient-web");
    }
    this.onlineConsultation.set("authToken", queryParams.consultation_token);
  }

  model() {
    return this.modelFor("patient-web.protected");
  }
}
