import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import SemeiaSessionService from "core/services/session";

export default class LoginRoute extends Route {
  @service declare session: SemeiaSessionService;
  @service declare router: RouterService;

  async beforeModel(): Promise<void> {
    if (this.session.isAuthenticated) {
      this.router.transitionTo("medics-web.logout");
    }
  }
}
