import Controller from "@ember/controller";
import { action } from "@ember/object";
import { ModelFrom } from "parog-web";
import Patient from "core/models/patient";
import Route from "./route";

export default class GlobalMedicalSynthesisDownloadController extends Controller {
  declare model: ModelFrom<Route>;

  get patientsConcerned(): Patient[] {
    return this.model.filter(p => p.followUpReason == "diabeto-dt2");
  }

  @action
  downloadAll() {
    const buttons = document.querySelectorAll(
      "[data-test-download]"
    ) as unknown as HTMLButtonElement[];
    buttons.forEach(e => e.click());
  }
}
