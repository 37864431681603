import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import SessionService from "ember-simple-auth/services/session";

export default class IndexRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  @service declare store: Store;

  beforeModel() {
    if (this.session.isAuthenticated) {
      const authenticatedAs = this.session.data.authenticated.as;

      if (authenticatedAs === "medic") {
        this.router.transitionTo("medics-web.protected.index");
        return;
      } else if (authenticatedAs === "patient") {
        this.router.transitionTo("patient-web.protected");
      }
    }
  }
}
