import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import WrModalContent from 'ember-wiser/components/wr/modal/content';
import WrButton from 'ember-wiser/components/wr/button';
import WrInputControl from 'ember-wiser/components/wr/form/controls/input';
import WrLinkTo from 'ember-wiser/components/wr/link-to';
import WrDivider from 'ember-wiser/components/wr/divider';
export default RouteTemplate(template(`
  <WrModalContent @targetModal='modal-1' @title='Connexion patient'>
    <form class='flex flex-col items-center py-4 gap-4 w-full'>
      <h3 class='wr primary header text-center'>
        Se connecter avec
        <br />
        votre compte existant
      </h3>

      <WrInputControl
        aria-label='Votre adresse e-mail '
        type='email'
        placeholder='Votre adresse e-mail '
        class='max-w-[355px] my-2'
        autocomplete='username'
      />

      <WrInputControl
        aria-label='Votre mot de passe '
        type='password'
        placeholder='Votre mot de passe '
        class='max-w-[355px] my-2'
        autocomplete='current-password'
      />

      <WrButton type='submit' @icon='ArrowRight'>
        Se connecter
      </WrButton>

      <WrLinkTo @route='v2.patient-password-forgotten'>
        J’ai oublié mon mot de passe
      </WrLinkTo>
    </form>

    <div class='flex flex-col items-center py-4 gap-4 w-full'>
      <WrDivider>ou</WrDivider>

      <WrLinkTo @route='v2.patient-link-login'>
        Je reçois un lien de connexion
      </WrLinkTo>

      <WrLinkTo @route='v2.patient-password-forgotten'>
        Je crée mon mot de passe
      </WrLinkTo>
    </div>
  </WrModalContent>
`, {
    eval () {
        return eval(arguments[0]);
    }
}));
