import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class SendForgotPasswordInstructionsController extends Controller {
  queryParams = ["email"];

  @service declare router: RouterService;

  @tracked email;

  @action
  onSubmitted() {
    this.router.transitionTo("medics-web.login");
  }
}
