import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import HealthCentre from "core/models/health-centre";

export default class ProfileController extends Controller {
  @service router;

  declare healthCentres: HealthCentre[];

  @action
  transitionToNextRoute() {
    this.router.transitionTo("medics-web.protected");
  }
}
