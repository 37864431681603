import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! prettier-ignore }}\n{{#if this.componentName}}\n  {{! @glint-expect-error }}\n  {{component this.componentName patient=@patient sourceJeId=@sourceJeId isReadOnly=@isReadOnly afterCompleted=@afterCompleted afterCancel=@afterCancel formAction=@formAction }}\n{{/if}}", {"contents":"{{! prettier-ignore }}\n{{#if this.componentName}}\n  {{! @glint-expect-error }}\n  {{component this.componentName patient=@patient sourceJeId=@sourceJeId isReadOnly=@isReadOnly afterCompleted=@afterCompleted afterCancel=@afterCancel formAction=@formAction }}\n{{/if}}","moduleName":"parog-web/components/medical-history/edition-form/index.hbs","parseOptions":{"srcName":"parog-web/components/medical-history/edition-form/index.hbs"}});
import ApplicationInstance from "@ember/application/instance";
import { getOwner } from "@ember/owner";
import Component from "@glimmer/component";
import Patient from "core/models/patient";

interface Args {
  patient: Patient;
}

export default class MedicalHistoryComponent extends Component<Args> {
  get componentName(): string | undefined {
    if (!this.args.patient.followUpReasonRecord) return;

    const area = this.args.patient.followUpReasonRecord.therapeuticalArea.id;
    const componentName = `medical-history/${area}`;

    // #region TODO: remove this region when all parog addons will be present in the same build
    const owner = getOwner(this) as ApplicationInstance;
    if (!owner.factoryFor(`component:${componentName}`)) return;
    // #endregion

    return `medical-history/${area}`;
  }
}
