import Route from "@ember/routing/route";
import RouteInfo from "@ember/routing/route-info";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { SemeiaPatientSession } from "core/services/session";
import logError from "core/utils/log-error";
import emberSwal from "ui-kit/utils/ember-swal";

export default class PatientWebRoute extends Route {
  @service declare session: SemeiaPatientSession;
  @service declare router: RouterService;

  async beforeModel(transition: Transition): Promise<void> {
    const queryParams = (transition.to as RouteInfo).queryParams;

    if (!this.session.isAuthenticated) {
      // Login the patient from the Access Token in params
      if (queryParams.access_token) {
        this.session.attemptedTransition = transition;
        await this.session
          .authenticate("authenticator:patient-implicit-with-at", queryParams)
          .catch(e => {
            logError(e, "pw.auth");
            this.router.transitionTo("patient-web.login");
          });
      }

      // Login the patient from the OTP in params
      if (queryParams.one_time_token) {
        this.session.attemptedTransition = transition;
        await this.session
          .authenticate("authenticator:patient-implicit", queryParams)
          .catch(e => {
            logError(e, "pw.auth");
            const description = e.error_description;
            if (description == "Token has expired") {
              emberSwal.fire(
                "Oups, votre lien est expiré.",
                "Merci de bien vouloir renouveler celui-ci.",
                "error"
              );
            }

            if (description == "Token is invalid") {
              emberSwal.fire(
                "Oups, votre lien n'est pas valide.",
                "Merci de bien vouloir renouveler celui-ci.",
                "error"
              );
            }
            this.router.transitionTo("patient-web.login");
          });
      }
    }
  }
}
