import { service } from "@ember/service";
import type ParogService from "core/services/parog";
import WrModalContent from "ember-wiser/components/wr/modal/content";
import PatientFeatureComponentController from "../../../../../feature/component/index/controller";

/**
 * Subclass `WrModalContent` to customize the `parentRouteName` method
 * The WrModalContent.prototype.parentRouteName would be `medics-web.protected.patients.patient.feature.component`
 * but we need it to be `medics-web.protected.patients.patient.feature`
 * to make sure `WrModalContent.prototype.parentRouteName` take us to the dashboard
 * */
class ForcModalContent extends WrModalContent {
  protected get parentRouteName() {
    return super.parentRouteName?.replace(/\.component$/, "");
  }
}

export default class PatientModalFeatureComponentController extends PatientFeatureComponentController {
  @service declare parog: ParogService;
  ForcModalContent = ForcModalContent;
  preserveScrollPosition = true;
}
