import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class PatientWebDashboardDeclareRoute extends Route {
  @service declare fetcher: FetcherService;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    await Promise.all([
      this.fetcher.loadRecords("personal-notes-changed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("treatment-history-changed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      })
    ]);

    return patient;
  }
}
