import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { SafeString } from "@ember/template/-private/handlebars";
import InsRetrievedJournalEvent from "cnam/models/ins-retrieved-journal-event";
import { InsStatus } from "cnam/types/types";
import { getInsStatusLabel } from "cnam/utils/ins";
import InsAccessedJournalEvent from "core/models/ins-accessed-journal-event";
import Patient from "core/models/patient";
import PatientAdministrativeDataUpdatedJournalEvent from "core/models/patient-administrative-data-updated-journal-event";
import PatientEnrolledJournalEvent from "core/models/patient-enrolled-journal-event";
import IntlService from "ember-intl/services/intl";
import { ModelFrom } from "parog-web";
import PatientIdentityAccessLogsRoute, {
  PatientIdentityAccessLogsEvent
} from "./route";

export default class PatientIdentityAccessLogsController extends Controller {
  declare model: ModelFrom<PatientIdentityAccessLogsRoute>;

  @service declare intl: IntlService;

  @action
  downloadCSV(): void {
    const encodedUri = encodeURI(this.csvContent);
    const anchorElement = document.createElement("a");
    document.body.appendChild(anchorElement);

    anchorElement.href = encodedUri;
    anchorElement.download = `historique_access_patient_${this.model.patient.id}.csv`;
    anchorElement.click();

    window.URL.revokeObjectURL(encodedUri);
  }

  get csvContent(): string {
    const rows: string[][] = [["Date", "Par", "Action", "Détails"]];

    document.querySelectorAll("table > tbody > tr").forEach(trElement => {
      const rowContent: string[] = [];

      const children = trElement.children;
      for (let i = 0; i < children.length; i++) {
        const child = children.item(i) as HTMLTableCellElement;
        let content = child.innerText.trim();
        if (content.length == 0) {
          content = " ";
        }
        // The quotes escape new line character, letting us having new lines in one cell.
        rowContent.push(`"${content}"`);
      }

      rows.push(rowContent);
    });

    return (
      "data:text/csv;charset=utf-8," + rows.map(row => row.join(",")).join("\n")
    );
  }

  @action
  actionNameForEvent(event: PatientIdentityAccessLogsEvent): string {
    if (event instanceof InsAccessedJournalEvent) {
      return "Accès à l'INS";
    } else if (event instanceof InsRetrievedJournalEvent) {
      return "Appel à l'INSi";
    } else if (
      event instanceof PatientAdministrativeDataUpdatedJournalEvent ||
      event instanceof PatientEnrolledJournalEvent
    ) {
      return "Édition des informations du patient";
    }

    throw "unexpected event type. Update actionNameForEvent.";
  }

  @action
  detailsForEvent(event: PatientIdentityAccessLogsEvent): string | SafeString {
    if (event instanceof InsAccessedJournalEvent) {
      return "—";
    } else if (event instanceof InsRetrievedJournalEvent) {
      const changes = this.insRetrievedTransformedChanges(event.identity);
      const message = this.changesBasedMessage(
        changes,
        this.insRetrievedMessageForKey.bind(this)
      );
      return htmlSafe(message);
    } else if (event instanceof PatientAdministrativeDataUpdatedJournalEvent) {
      const changes = this.administrativeDataTransformedChanges(
        event.informationChanges
      );
      const message = this.changesBasedMessage(
        changes,
        this.administrativeDataMessageForKey.bind(this)
      );
      return htmlSafe(message);
    } else if (event instanceof PatientEnrolledJournalEvent) {
      return htmlSafe(
        `<ul class="ui list"><li>statut : ${getInsStatusLabel(
          InsStatus.Provisional
        )}</li></ul>`
      );
    }

    throw "unexpected event type. Update detailsForEvent.";
  }

  private changesBasedMessage<K extends string, V>(
    changes: Record<K, V>,
    messageBuilder: (key: K, value: V) => string
  ): string {
    let message = '<ul class="ui list">';
    for (const key in changes) {
      message += messageBuilder(key, changes[key]);
    }
    message += "</ul>";
    return message;
  }

  private insRetrievedMessageForKey<
    K extends keyof InsRetrievedJournalEvent["identity"]
  >(key: K, value: InsRetrievedJournalEvent["identity"][K]) {
    let displayedKeyLabel = this.intl.t(
      `identity-access-logs.${key as string}`
    );
    let displayedValue = value;

    switch (key) {
      case "result":
        displayedKeyLabel = "réponse du service INSi";
        break;
      case "sex":
        displayedValue = this.sexLabelForValue(value);
    }

    return `<li>${displayedKeyLabel} : ${displayedValue}</li>`;
  }

  private insRetrievedTransformedChanges(
    changes: InsRetrievedJournalEvent["identity"]
  ): Partial<InsRetrievedJournalEvent["identity"]> {
    const result = Object.assign({}, changes);

    delete result["status"];
    delete result["history"];

    if (result.birthday) {
      result.birthday = this.intl.formatDate(new Date(result.birthday), {
        format: "DD/MM/YYYY"
      });
    }

    result.insOid = result.identity.oid;
    result.insValue = result.identity.value;
    result.insKey = result.identity.key;
    result.fullInsValue = `${result.insValue}${result.insKey}`;
    delete result.insValue;
    delete result.insKey;

    delete result.identity;

    return result;
  }

  private administrativeDataMessageForKey<K extends keyof Patient>(
    key: K,
    value: Patient[K]
  ): string {
    let displayedValue = value;

    switch (key) {
      case "insStatus":
        displayedValue = getInsStatusLabel(value as InsStatus);
        break;
      case "gender":
        displayedValue = this.sexLabelForValue(value);
        break;
      case "encryptedPassword":
        return "<li>changement de mot de passe</li>";
      case "insIdentityDocument": {
        // FIXME this should be a translation, to share this with AdministrativePatientFormProviderInsFieldsComponent.defaultInsTrustableIdentityDocDicts and other part of this component.
        const translations = [
          {
            key: "passport",
            value: "Passeport"
          },
          {
            key: "idCard",
            value: "Carte Nationale d’Identité"
          },
          {
            key: "birthAct",
            value:
              "Extrait d’acte de naissance ou livret de famille pour un mineur"
          },
          {
            key: "residencePermit",
            value: "Titre ou carte de séjour"
          },
          {
            key: "digitalDevice",
            value:
              "Dispositif numérique d’identification/authentification " +
              "forte (exemple: carte nationale d’identité électronique)."
          },
          { key: "other", value: "Autre" },
          { key: "nothing", value: "Aucune" }
        ];

        displayedValue =
          translations.filter(translation => translation.key == value)[0]
            ?.value || "Autre";
      }
    }

    return `<li>${this.intl.t(
      `identity-access-logs.${String(key)}`
    )} : ${displayedValue}</li>`;
  }

  /**
   * This method removes some keys from `changes`,
   * and concatenates `insValue` and `insKey` which are expected to be displayed together.
   */
  private administrativeDataTransformedChanges(
    changes: Record<keyof Patient, unknown>
  ): Record<keyof Patient, unknown> {
    const result = Object.assign({}, changes);

    if (result.insValue || result.insKey) {
      result.fullInsValue = `${result.insValue}${result.insKey}`;
      delete result.insValue;
      delete result.insKey;
    }
    // This information will be indirectly seen through ins_status and ins_identity_document.
    delete result.insDocumentIsQualifying;

    // This contains additional info generally not interesting to display
    delete result.associatedData;

    // Back only fields, exposed through the PatientAdministrativeDataUpdatedJournalEvent
    // FIXME these information should probably be filtered on API side.
    delete result["withingsAccessToken"];
    delete result["withingsRefreshToken"];

    for (const key in changes) {
      if (key.startsWith("normalized")) {
        delete result[key];
      }
    }

    delete result["resetPasswordToken"];
    delete result["twoFactorCode"];
    delete result["twoFactorAuthentifiedDeviceTokens"];
    delete result["refreshTokensEnabled"];
    delete result["refreshTokensEnabledAt"];

    return result;
  }

  private sexLabelForValue(value): string | undefined {
    switch (value) {
      case "male":
        return "homme";
      case "female":
        return "femme";
    }

    return undefined;
  }
}
