/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { service } from "@ember/service";
import OkChimioSnapshotCapturedJournalEvent from "core/models/ok-chimio-snapshot-captured-journal-event";
import { sleep } from "core/utils/time";
import dayjs from "dayjs";
import { task } from "ember-concurrency";
import html2pdf from "html2pdf.js";
import { ModelFrom } from "parog-web";
import type Route from "./route";

export default class PreTreatmentSynthesisController extends Controller {
  declare model: ModelFrom<Route>;
  @service declare store: Store;

  queryParams = [
    "treatmentId",
    "protocolUuid",
    "cureKey",
    "snapshotCapturedId"
  ];

  treatmentId?: string;
  protocolUuid?: string;
  cureKey?: string;
  snapshotCapturedId?: string;

  get okChimioSnapshotCapturedJournalEvent(): OkChimioSnapshotCapturedJournalEvent | null {
    return this.snapshotCapturedId
      ? this.store.peekRecord(
          "ok-chimio-snapshot-captured-journal-event",
          this.snapshotCapturedId
        )
      : null;
  }

  exportPdfTask = task(async event => {
    const htmlToPrint = event.target.offsetParent;

    // Open acordion
    htmlToPrint
      .querySelectorAll(`.ui.accordion > .title`)
      .forEach((e: HTMLDivElement) => {
        if (!e.classList.contains("active")) e.click();
      });

    // Waiting for animation
    await sleep(1000);

    // Remove save button
    const clone = htmlToPrint.cloneNode(true);
    const container = clone.children[0].children[1];

    container.querySelector("#action-buttons").remove();

    const opt = {
      margin: [5, 10, 5, 10],
      filename: `ok_chimio_${dayjs(
        this.okChimioSnapshotCapturedJournalEvent?.occurredAt
      ).format("DD-MM-YYYY")}.pdf`
    };

    html2pdf(clone.innerHTML, opt);
  });
}
