import Route from "@ember/routing/route";
import { service } from "@ember/service";
import SemeiaSessionService from "core/services/session";

export default class PatientWebLoginRoute extends Route {
  @service declare session: SemeiaSessionService;

  async beforeModel() {
    // The session is invalidated when entering this route.
    // This ease the work in the rest of the app: when we detect the user should
    // connect/reconnect, we just have to redirect him to the login page, without
    // thinking about the session handling.
    await this.session.invalidate();
  }
}
