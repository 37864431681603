import Store from "@ember-data/store";
import type ModelRegistry from "@ember-data/types/registries/model";
import { service } from "@ember/service";
import FetcherService from "core/services/fetcher";
import { SemeiaMedicSession } from "core/services/session";
import PatientListFilterSerializer from "core/utils/patient-list-filter-serializer";
import MedicsWebIndexTabRoute, {
  MedicsWebTabModel,
  MedicsWebTabParams
} from "../tab/route";

export default class MedicsWebIndexPatientListRoute extends MedicsWebIndexTabRoute {
  @service declare store: Store;
  @service declare session: SemeiaMedicSession;
  @service declare fetcher: FetcherService;

  get tabType() {
    return "patient-list";
  }

  async model(params: MedicsWebTabParams): Promise<MedicsWebTabModel> {
    const model = await super.model(params);

    // These are JEs used in the list of patient.
    await this.loadMostRecentPatientJEs(model.filter);

    return model;
  }

  private loadMostRecentPatientJEs(
    filter: PatientListFilterSerializer
  ): Promise<unknown> {
    const types: Array<keyof ModelRegistry> = [
      "adherence-prediction-computed-journal-event",
      "toxicity-prediction-computed-journal-event",
      "medical-history-changed-journal-event"
    ];

    return Promise.all(
      types.map(type =>
        this.fetcher.loadRecords(type, {
          filter: Object.assign(filter.journalEventsApiFilter, {
            most_recent: ""
          })
        })
      )
    );
  }
}
