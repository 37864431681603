import Store from "@ember-data/store";
import { service } from "@ember/service";
import { CnamManagerAdapter } from "cnam/cnam-adapters/cnam-manager-adapter";
import CnamManagerFactory from "cnam/cnam-adapters/cnam-manager-factory";
import DmpBulkDownloadContext from "cnam/contexts/dmp-bulk-download-context";
import { hasNoReimbursementSinceYesterday } from "cnam/utils/dmp-utils";
import MedicModel from "core/models/medic";
import Patient from "core/models/patient";
import FeaturesService from "core/services/features";
import FetcherService from "core/services/fetcher";
import MedicsWebIndexTabRoute, {
  MedicsWebTabModel,
  MedicsWebTabParams
} from "../tab/route";

interface Model extends MedicsWebTabModel {
  cnamManagerAdapter: CnamManagerAdapter;
  dmpBulkDownloadContext: DmpBulkDownloadContext;
}

export default class MedicsWebIndexDmpBulkDownloadRoute extends MedicsWebIndexTabRoute {
  @service declare store: Store;
  @service declare features: FeaturesService;
  @service declare fetcher: FetcherService;

  get tabType() {
    return "dmp-bulk-download";
  }

  async model(params: MedicsWebTabParams): Promise<Model> {
    const model = await super.model(params);

    const fullPatients = await this.store.query("patient", {
      ids: model.patients.map(patient => patient.id).join(",")
    });

    await this.fetcher.loadRecords("reimbursements-retrieved-journal-event", {
      filter: Object.assign({}, model.filter.journalEventsApiFilter, {
        most_recent: ""
      })
    });

    const cnamManagerAdapter = CnamManagerFactory.getCnamManagerAdapter(
      this.session.medic as MedicModel,
      this.store,
      this.fetcher,
      this.session.access_token as string,
      this.features
    );

    const dmpBulkDownloadSessionStartedJournalEvent = await this.store
      .createRecord("dmp-bulk-download-session-started-journal-event", {
        occurredAt: new Date(),
        cnamProcessStartedJournalEvents: []
      })
      .save();

    const sortedByDmpUpdateDateDescPatientsToTreat = fullPatients
      .slice()
      .toSorted((a, b) => {
        const firstDate = a.lastDmpUpdateDate;
        const secondDate = b.lastDmpUpdateDate;
        if (!firstDate) {
          return -1;
        }
        if (!secondDate) {
          return 1;
        }
        return firstDate.getTime() - secondDate.getTime();
      });

    const eligibleSortedPatientsToTreat =
      sortedByDmpUpdateDateDescPatientsToTreat.filter(patient =>
        this.isPatientEligible(patient)
      );

    const notEligibleButEnrolledPatientsToTreat =
      sortedByDmpUpdateDateDescPatientsToTreat.filter(
        patient => !this.isPatientEligible(patient) && patient.isEnrolled
      );

    const dmpBulkDownloadContext = new DmpBulkDownloadContext(
      cnamManagerAdapter.name,
      eligibleSortedPatientsToTreat,
      notEligibleButEnrolledPatientsToTreat,
      cnamManagerAdapter.insiAdapter,
      cnamManagerAdapter.dmpAdapter,
      cnamManagerAdapter.authenticationContext,
      this.store,
      this.fetcher,
      dmpBulkDownloadSessionStartedJournalEvent
    );

    return Object.assign(
      {
        cnamManagerAdapter,
        dmpBulkDownloadContext
      },
      model
    );
  }

  private isPatientEligible(patient: Patient): boolean {
    return (
      patient.isEnrolled &&
      hasNoReimbursementSinceYesterday(patient) &&
      patient.authorizesDmpAccess
    );
  }
}
