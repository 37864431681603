import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class PatientWebProtectedConsultMedicalSynthesisRoute extends Route {
  @service declare fetcher: FetcherService;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    let allRequests = this.fetchMedicalSynthesisJesReq(patient) as Array<any>;

    if (patient.therapeuticalArea?.id == "mental") {
      allRequests = allRequests.concat(this.fetchJesForMentalwiseReq(patient));
    }

    await Promise.all(allRequests);

    return patient;
  }

  fetchMedicalSynthesisJesReq(patient) {
    return [
      // Entourage de soins section
      this.fetcher.loadRecords("care-giver-added-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("referent-lab-updated-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      })
    ];
  }

  fetchJesForMentalwiseReq(patient) {
    return [
      // Observance et bon usage des traitements super section
      this.fetcher.loadRecords(
        "medicines-reconciliation-changed-journal-event",
        {
          filter: {
            patient_id: patient.id
          },
          backgroundReload: false
        }
      ),
      // Objectifs à 3 mois super section
      this.fetcher.loadRecords("three-months-goals-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      })
    ];
  }
}
