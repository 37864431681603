import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class PatientWebProtectedConsultBioConstantsRoute extends Route {
  @service declare fetcher: FetcherService;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    const allRequests = this.fetchVitalSignJesReq(patient).concat([
      this.fetcher.loadRecords("ctcae-filled-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("anxiety-filled-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("pre-treatment-filled-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("inter-treatment-filled-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("survey-completed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("bio-exam-results-received-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        include: ["file-attachments", "creator"].join(","),
        backgroundReload: false
      }),
      this.fetcher.loadRecords("medical-event-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("compound-survey-completed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("personal-notes-changed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      })
    ]);

    await Promise.all([allRequests]);

    return patient;
  }

  fetchVitalSignJesReq(patient) {
    let requests = this._fetchCoreVitalSignJesReq(patient) as Array<any>;

    if (patient.therapeuticalArea?.id == "nephro") {
      requests = requests.concat(this._fetchNephroVitalSignJesReq(patient));
    }

    if (patient.therapeuticalArea?.id == "onco") {
      requests = requests.concat(this._fetchOncoVitalSignJesReq(patient));
    }

    if (patient.therapeuticalArea?.id == "mental") {
      requests = requests.concat(this._fetchMentalVitalSignJesReq(patient));
    }

    if (patient.therapeuticalArea?.id == "rhumato") {
      requests = requests.concat(this._fetchRhumatoVitalSignJesReq(patient));
    }

    return requests;
  }

  _fetchCoreVitalSignJesReq(patient) {
    return [
      this.fetcher.loadRecords("weight-measured-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("height-measured-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      // Only fetch last session as other sessions are fetched on dedicated routes
      this.fetcher.loadRecords("blood-pressure-session-started-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        include: "blood-pressure-measured-journal-events",
        backgroundReload: false
      }),
      this.fetcher.loadRecords("temperature-measured-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("respiratory-rate-measured-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      // Don't fetch only `most_recent` here bc we need more than the last
      // Actually we need the last postPrandial and the last postPrandial
      this.fetcher.loadRecords(
        "capillarity-blood-glucose-measured-journal-event",
        {
          filter: {
            patient_id: patient.id
          },
          backgroundReload: false
        }
      ),
      this.fetcher.loadRecords("adverse-event-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("kfre-computed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      })
    ];
  }

  _fetchNephroVitalSignJesReq(patient) {
    return [
      this.fetcher.loadRecords("vems-demm-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("oxygene-saturation-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("dp-cycle-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("hemodialysis-session-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      })
    ];
  }

  _fetchOncoVitalSignJesReq(patient) {
    return [
      this.fetcher.loadRecords("mammography-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("fact-lym-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      })
    ];
  }

  _fetchMentalVitalSignJesReq(patient) {
    return [
      this.fetcher.loadRecords("abdominal-area-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("feeling-evas-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      })
    ];
  }

  _fetchRhumatoVitalSignJesReq(patient) {
    return [
      this.fetcher.loadRecords("das28-crp-computed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("das28-vs-computed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("das28-sdai-computed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("das28-cdai-computed-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("pr-articulations-counted-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords(
        "pr-feeling-eva-patient-declared-journal-event",
        {
          filter: {
            patient_id: patient.id,
            most_recent: ""
          },
          backgroundReload: false
        }
      ),
      this.fetcher.loadRecords("pr-feeling-eva-medic-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("inflammatory-eruption-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("infection-declared-journal-event", {
        filter: {
          patient_id: patient.id,
          most_recent: ""
        },
        backgroundReload: false
      })
    ];
  }
}
