import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import BioExamResultsReceivedJournalEvent from "core/models/bio-exam-results-received-journal-event";
import { sortBy } from "core/utils/sort";
import { ModelFrom } from "parog-web";
import type Route from "./route";

export default class BioExamsSummaryController extends Controller {
  declare model: ModelFrom<Route>;

  @service declare router: RouterService;

  @tracked sortCriterion = "occurredAt";
  @tracked sortOrder = "desc";
  preserveScrollPosition;

  @action
  onSortCriterionChange(sortId: string, isAscending: boolean): void {
    this.preserveScrollPosition = true;
    this.sortCriterion = sortId;
    this.sortOrder = isAscending ? "asc" : "desc";
  }

  get sortedJournalEvents(): BioExamResultsReceivedJournalEvent[] {
    return this.model.slice().sort(sortBy(this.sortCriterion, this.sortOrder));
  }
}
