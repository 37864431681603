import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class PatientWebDashboardRoute extends Route {
  @service declare fetcher: FetcherService;
  @service declare store: Store;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;
    await Promise.all([
      this.fetcher.loadRecords("task-planned-journal-event", {
        filter: {
          patient_id: patient.id,
          responsible_type: "patient",
          processed: "false"
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("recursive-task-planned-journal-event", {
        filter: { patient_id: patient.id },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("survey-requested-journal-event", {
        filter: { patient_id: patient.id },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("treatment-history-changed-journal-event", {
        filter: { patient_id: patient.id, most_recent: "" },
        backgroundReload: false
      })
    ]);

    return patient;
  }
}
