import Store from "@ember-data/store";
import ArrayProxy from "@ember/array/proxy";
import Route from "@ember/routing/route";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import AlertTriggeredJournalEvent from "core/models/alert-triggered-journal-event";
import Patient from "core/models/patient";
import type { SemeiaMedicSession } from "core/services/session";
import PatientListFilterSerializer, {
  JournalEventApiFilters
} from "core/utils/patient-list-filter-serializer";
import { getItemFromLocalStorage } from "core/utils/use-local-storage";
import dayjs from "dayjs";
import Ember from "ember";
import PrescriptionsController from "./controller";

interface PrescriptionModel {
  alertJes: AlertTriggeredJournalEvent[];
  patients: Patient[];
}

export default class PrescriptionsRoute extends Route {
  @service declare session: SemeiaMedicSession;
  @service declare store: Store;

  async model(): Promise<PrescriptionModel> {
    const patients = await this._loadPatients();
    const alertJes = await this._loadAlertJournalEvents();

    return {
      patients: patients.slice(),
      alertJes: alertJes.filter(
        je => je.isEtapesPrescriptionAlert || je.isTlsPrescriptionAlert
      )
    };
  }

  private async _loadPatients(): Promise<ArrayProxy<Patient>> {
    return this.store.query("patient", {
      filter: Object.assign(this.filter.patientApiFilter, {
        enrolled_before: dayjs().subtract(1, "M").format("YYYY-MM-DD"),
        has_agreed_tls_consent: true,
        with_tls_prescribed_in_progress: true,
        has_not_validated_trial_period: true
      }),
      include: [
        "medics",
        "health-centre",
        "patient-enrolled-journal-event",
        "patient-disenrolled-journal-event"
      ].join(",")
    });
  }

  /**
   * Load AlertTriggeredJournalEvent whose patient correspond to the filter of the Patient List page.
   */
  private async _loadAlertJournalEvents(): Promise<
    ArrayProxy<AlertTriggeredJournalEvent>
  > {
    return this.store.query(
      "alert-triggered-journal-event",
      this.journalEventOptions
    );
  }

  /**
   *  The journalEventOptions hash is isolated in a getter to simplify route unit tests
   */
  get journalEventOptions(): {
    include: string;
    filter: { non_processed: string } & JournalEventApiFilters;
  } {
    return {
      filter: Object.assign(
        { non_processed: "", remote_monitoring_related: "" },
        this.filter.journalEventsApiFilter
      ),
      include: [
        "triggering-journal-event",
        "patient.medics.health-centres",
        "patient.health-centre",
        "patient.medical-history-changed-journal-event",
        "patient.patient-enrolled-journal-event"
      ].join(",")
    };
  }

  /**
   * The filter is displayed in the route template, so we need to pass it to the controller.
   */
  setupController(
    controller: PrescriptionsController,
    model: AlertTriggeredJournalEvent[],
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.filter = this.filter;
  }

  // FIXME: the filter code should be shared with the one in the Patient List route.
  /**
   * This getter returns an instance of the class `PatientListFilterSerializer` which contains
   * the JE & patient API filters. These filters are set according to the filter contained in
   * the local storage, which is either the filter selected on medics-web homepage or the
   * `_defaultFilter`
   */
  get filter(): PatientListFilterSerializer {
    const filter =
      getItemFromLocalStorage(this._selectedFilterKey) || this._defaultFilter;

    return new PatientListFilterSerializer({
      serializedFilter: filter,
      store: this.store
    });
  }

  /**
   * This will be the filter value if no filter is found in the local storage
   */
  private get _defaultFilter(): string {
    return `fur%3Dall_medicId%3D${this.session.medic.id}_healthCentreId%3Dundefined_patientStatus%3Denrolled_taskType%3Dall_responsibleId%3Dall`;
  }

  /**
   * We get the selected filter from the local storage, because it is possible that
   * the user navigates to this route not directly from the `medics-web` route.
   * Maybe in the future, this page will have its own filters.
   */
  private get _selectedFilterKey(): string {
    const key = "medics-web.protected.updates.selectedFilter";
    if (Ember.testing) {
      return `test-${key}`;
    }
    return key;
  }
}
