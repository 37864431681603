import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.componentName}}\n  {{! @glint-expect-error }}\n  {{component this.componentName patient=@patient}}\n{{/if}}", {"contents":"{{#if this.componentName}}\n  {{! @glint-expect-error }}\n  {{component this.componentName patient=@patient}}\n{{/if}}","moduleName":"parog-web/components/dashboard/pathologies-section/index.hbs","parseOptions":{"srcName":"parog-web/components/dashboard/pathologies-section/index.hbs"}});
import ApplicationInstance from "@ember/application/instance";
import { getOwner } from "@ember/owner";
import Component from "@glimmer/component";
import Patient from "core/models/patient";

interface Args {
  patient: Patient;
}

export default class PathologiesSection extends Component<Args> {
  get componentName(): string | undefined {
    const area = this.args.patient.therapeuticalArea?.id;
    const componentName = `dashboard/pathologies-section/${area}`;

    // #region TODO: remove this region when all parog addons will be present in the same build
    const owner = getOwner(this) as ApplicationInstance;
    if (!owner.factoryFor(`component:${componentName}`)) return;
    // #endregion

    return `dashboard/pathologies-section/${area}`;
  }
}
