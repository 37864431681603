import Store from "@ember-data/store";
import Controller from "@ember/controller";
import Route from "@ember/routing/route";
import RouteInfo from "@ember/routing/route-info";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import CableService from "core/services/cable";
import { SemeiaMedicSession } from "core/services/session";
import { apiFieldset } from "core/utils/api-fieldset";
import PatientListFilterSerializer from "core/utils/patient-list-filter-serializer";
import {
  initialPatientListFilter,
  savePatientListFilter
} from "core/utils/patient-list-filter-utils";
import { setItemInLocalStorage } from "core/utils/use-local-storage";
import { medicsWebIndexLastRouteKey } from "../route";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from "ember-data";
import { proIrcFur } from "pro-irc/models/pro-irc-status-updated-journal-event";

export interface MedicsWebTabParams extends Record<string, string> {
  sortCriterion: string;
  sortOrder: string;
  selectedFilter: string;
}

export interface MedicsWebTabModel {
  serializedFilter: string;
  filter: PatientListFilterSerializer;
  patients: MedicsWebIndexPatient[];
}

/**
 * The route fetches a partial patient using `fields` query param of JSON API.
 * To communicate this to components related to this route, we create a type
 * representing the resulting subset of patient.
 */
export type MedicsWebIndexPatient = Pick<
  Patient,
  "id" | "firstName" | "lastName" | "email" | "phone" | "isProIrc"
>;

export default class MedicsWebIndexTabRoute extends Route<MedicsWebTabModel | null> {
  @service declare store: Store;
  @service declare session: SemeiaMedicSession;
  @service declare cable: CableService;

  private subscribedToPatientJEsUpdates = false;
  currentModel: MedicsWebTabModel | null = null;

  queryParams = {
    selectedFilter: {
      refreshModel: true
    }
  };

  get tabType(): string {
    throw new Error("You must define a tabType getter");
  }

  async model(params: MedicsWebTabParams) {
    const serializedFilter =
      params.selectedFilter ||
      initialPatientListFilter(this.store, this.session.medic);

    savePatientListFilter(serializedFilter);

    const filter = new PatientListFilterSerializer({
      serializedFilter,
      store: this.store
    });

    const patients = this.filterProIrcPatients(
      await this.loadPatients(filter),
      filter
    );

    const model: MedicsWebTabModel = {
      serializedFilter,
      filter,
      patients
    };

    this.currentModel = model; // Store the model
    return model;
  }

  activate(transition: Transition): void {
    super.activate(transition);
    setItemInLocalStorage(
      medicsWebIndexLastRouteKey,
      (transition.to as RouteInfo).name
    );
    if (this.currentModel) {
      this.subscribeToPatientJEsUpdates(this.currentModel);
    }
  }

  deactivate(transition: Transition): void {
    super.deactivate(transition);
    if (this.currentModel) {
      this.unsubscribeToPatientJEsUpdates(this.currentModel);
    }
  }

  private filterProIrcPatients(
    patients: DS.AdapterPopulatedRecordArray<Patient>,
    filter: PatientListFilterSerializer
  ): MedicsWebIndexPatient[] {
    if (filter.parser.patientFur == proIrcFur) {
      return patients.filter(p => p.isProIrc);
    }
    return patients.slice();
  }

  private subscribeToPatientJEsUpdates(model: MedicsWebTabModel) {
    if (this.subscribedToPatientJEsUpdates) return;

    model.patients.forEach(patient => {
      this.cable.subscribeToReloadChannel(patient);
    });

    this.subscribedToPatientJEsUpdates = true;
  }

  private unsubscribeToPatientJEsUpdates(model: MedicsWebTabModel) {
    model.patients.forEach(patient =>
      this.cable.unsubscribeToReloadChannel(patient)
    );

    this.subscribedToPatientJEsUpdates = false;
  }

  private async loadPatients(filter: PatientListFilterSerializer) {
    const patientOptions = {
      filter: filter.patientApiFilter,
      include: [
        "medics",
        "health-centre",
        "patient-enrolled-journal-event",
        "patient-disenrolled-journal-event",
        "pro-irc-status-updated-journal-event"
      ].join(","),
      fields: apiFieldset("patient", [
        "firstName",
        "lastName",
        "birthFirstName",
        "firstBirthFirstName",
        "birthName",
        "birthday",
        "gender",
        "insValue",
        "computedNationalHealthNumber",
        "socialSecurityNumber",
        "phone",
        "email",
        "medics",
        "clinicalStudyId"
      ])
    };
    return await this.store.query("patient", patientOptions);
  }

  resetController(controller: Controller): void {
    controller.set("preserveScrollPosition", false);
  }
}
