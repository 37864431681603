import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import emberSwal from "ui-kit/utils/ember-swal";
import { MedicForList, PatientMedicsRouteModel } from "./route";
import { referentMedics } from "./utils";

export default class PatientMedicsController extends Controller {
  @service declare router: RouterService;
  @service declare store: Store;

  declare model: PatientMedicsRouteModel;

  get patient(): PatientMedicsRouteModel["patient"] {
    return this.model.patient;
  }

  get btnContainer() {
    return document.getElementById("medic-edition-save-btn-container");
  }

  get referentMedicForLists(): MedicForList[] {
    return referentMedics(this.model.medicsByHealthCentres);
  }

  get onlyOneMedicSelected(): boolean {
    return this.referentMedicForLists.length == 1;
  }

  @action
  toggleSelection(medicSelection: MedicForList, checked: boolean) {
    if (checked) {
      medicSelection.isReferent = checked;
    } else {
      this.referentMedicForLists
        .filter(medic => medic.id == medicSelection.id)
        .forEach(medic => {
          medic.isReferent = checked;
        });
    }
  }

  saveReferents = task(async () => {
    try {
      const referentMedics = this.referentMedicForLists.map(m =>
        this.store.peekRecord("medic", m.id)
      );
      const uniqArray = [...new Set(referentMedics)];
      this.patient.set("medics", uniqArray);
      await this.patient.save();

      this.router.transitionTo(
        "medics-web.protected.patients.patient.administrative"
      );
    } catch {
      emberSwal.fire({
        title: "Oups, une erreur s'est produite",
        text: "Merci de bien vouloir recommencer ultérieurement",
        icon: "error"
      });
    }
  });
}
