import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";

import Patient from "core/models/patient";

export default class ResultsTableTemperatureRoute extends Route {
  @service declare store: Store;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    await this.store.query("temperature-measured-journal-event", {
      filter: { patient_id: patient.id }
    });

    return patient;
  }
}
