import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import MedicationPrescribedJournalEvent from "core/models/medication-prescribed-journal-event";
import Patient from "core/models/patient";
import { Changeset } from "ember-changeset";
import lookupValidator from "ember-changeset-validations";
import { validatePresence } from "ember-changeset-validations/validators";
import { ValidatorMap } from "ember-changeset/types";
import MedicationPrescriptionEditionFormController from "./controller";

interface Params extends Record<string, unknown> {
  "old-prescription-id": number;
}

export default class BioPrescriptionEditionFormRoute extends Route {
  @service declare store: Store;
  patient: Patient | undefined;
  oldMedicationPrescribed: MedicationPrescribedJournalEvent | undefined;

  queryParams = {
    ["old-prescription-id"]: {
      refreshModel: true
    }
  };

  get validations(): ValidatorMap {
    return {
      validityPeriod: validatePresence({
        presence: true,
        description: "La durée de validité"
      })
    };
  }

  setupController(
    controller: MedicationPrescriptionEditionFormController,
    model: any,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.set("patient", this.patient);
    controller.set("oldMedicationPrescribed", this.oldMedicationPrescribed);
  }

  model(params: Params) {
    this.patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    const model = this.store.createRecord(
      "medication-prescribed-journal-event",
      {
        occurredAt: new Date(),
        patient: this.patient,
        createExpirationAlert: true
      }
    );

    const oldPrescriptionId = params["old-prescription-id"];
    if (oldPrescriptionId) {
      this.oldMedicationPrescribed = this.store.peekRecord(
        "medication-prescribed-journal-event",
        oldPrescriptionId
      ) as MedicationPrescribedJournalEvent;

      if (
        this.oldMedicationPrescribed.medications?.length &&
        this.patient.lastTreatmentPrescriptionChangedJournalEvent
      ) {
        model.medications =
          this.patient.lastTreatmentPrescriptionChangedJournalEvent?.prescriptions.slice();
      }
      model.validityPeriod = this.oldMedicationPrescribed.validityPeriod;
    }

    return Changeset(
      model,
      lookupValidator(this.validations),
      this.validations
    );
  }
}
