import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { InsStatus } from "cnam/types/types";
import HealthCentre from "core/models/health-centre";
import MedicModel from "core/models/medic";
import MedicalHistoryChangedJournalEvent from "core/models/medical-history-changed-journal-event";
import Patient from "core/models/patient";
import { getDateFromString } from "core/utils/time";
// @ts-expect-error We need ESM import to integrate polyfills
import { parse } from "csv-parse/browser/esm/sync";
import dayjs from "dayjs";
import FlashMessagesService from "ember-cli-flash/services/flash-messages";
import { task } from "ember-concurrency";

/**
 * Here to make the corresponding between the attribute in the CSV file
 * and our Patient attributes.
 */
const correspondenceTable: Map<string, string> = new Map([
  // Patient
  ["Prénom", "firstName"],
  ["Nom marital", "lastName"],
  ["Nom de naissance", "birthName"],
  ["Date de naissance Receveur", "birthday"],
  ["Sexe Receveur", "gender"],
  ["Adresse e-mail du patient", "email"],
  ["Téléphone receveur habituel", "phone"],
  ["Adresse résidence habituelle", "address"],
  ["Code postal résidence habituelle", "postcode"],
  ["Ville résidence habituelle", "city"],
  ["Complément adresse résidence habituelle", "moreAddressInformation"],
  ["Numéro IPP", "ippNumber"],
  ["Numéro sécu", "insuredSocialSecurityNumber"],
  ["Médecin référent", "medics"],
  // Health Centre
  ["ID du centre", "healthCentre"],
  // Medical History
  ["Date de greffe", "transplantDate"],
  ["Néphropathie", "nephropatie"],
  ["Type de donneur attendu", "donorType"],
  ["MRC", "mrcStage"]
]);

const mandatoryValues = [
  "firstName",
  "lastName",
  "birthday",
  "phone",
  "healthCentre"
];

const columnsCanBeDisplayed = [
  "firstName",
  "lastName",
  "birthName",
  "birthday",
  "gender",
  "email",
  "phone",
  "address",
  "postcode",
  "city",
  "moreAddressInformation",
  "ippNumber",
  "insuredSocialSecurityNumber",
  "medics",
  "healthCentre"
];

interface PatientWithJEs {
  patient: Patient;
  medicalHistory: MedicalHistoryChangedJournalEvent;
  invalidValues: string[];
}

interface CsvPatientRow {
  firstName: string;
  lastName: string;
  birthName?: string;
  birthday: string;
  gender?: string;
  email?: string;
  phone: string;
  address?: string;
  postcode?: string;
  city?: string;
  moreAddressInformation?: string;
  ippNumber?: string;
  insuredSocialSecurityNumber?: string;
  medics?: string;
  // Health Centre
  healthCentre: string;
  // Medical History
  transplantDate?: string;
  nephropatie?: string;
  donorType?: string;
  mrcStage?: string;
}

export default class PatientsImportController extends Controller {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessagesService;

  @tracked columnNames: string[][] = [];
  @tracked patients: PatientWithJEs[] = [];
  @tracked isImported = false;

  getFormatedPatientProperty(patient: Patient, propertyName: string): string {
    if (patient[propertyName] instanceof Date) {
      return dayjs(patient[propertyName]).format("DD/MM/YYYY");
    }
    if (propertyName == "medics") {
      const medics = patient.medics.map(medic => {
        return medic.fullName;
      });
      return medics.join(", ");
    }
    if (patient[propertyName] instanceof HealthCentre) {
      return patient[propertyName].name;
    }
    return patient[propertyName];
  }

  @action
  onFileSelected(event: Event) {
    // Must be split for testing purpose
    this.readFiles.perform(event);
  }

  readFiles = task(async event => {
    const files = event.target.files;
    // If we get a file
    if (files.length <= 0) return;

    const reader = new FileReader();
    reader.onload = () => {
      const fileParsed = parse(reader.result as string, {
        delimiter: ";",
        columns: true
      });

      const fileColumnNames = Object.keys(fileParsed[0]);
      correspondenceTable.forEach((patientKey, sheetKey) => {
        if (
          fileColumnNames.includes(sheetKey) &&
          columnsCanBeDisplayed.includes(patientKey)
        ) {
          this.columnNames.push([sheetKey, patientKey]);
        }
      });

      this.patients = fileParsed.map((row: { [x: string]: string }) => {
        return this.createPatientAndMedicalHistory(row);
      });
    };

    reader.readAsText(files[0]);
  });

  savePatientsTask = task(async () => {
    this.patients.forEach(async (patient: PatientWithJEs) => {
      if (!patient.invalidValues.length) {
        await patient.patient.save();
        await patient.medicalHistory.save();
      }
    });
    this.isImported = true;
  });

  private createPatientAndMedicalHistory(row: any) {
    this.transformKeys(row);

    const patient = this.store.createRecord(
      "patient",
      this.getPatientStructured(row)
    );

    const medicalHistory = this.store.createRecord(
      "medical-history-changed-journal-event",
      this.getMedicalHistoryStructured(patient, row)
    );

    return {
      patient: patient,
      medicalHistory: medicalHistory,
      invalidValues: this.getInvalidValues(patient)
    };
  }

  private transformKeys(row: any) {
    correspondenceTable.forEach(function (patientKey, sheetKey) {
      row[patientKey] = row[sheetKey];
      delete row[sheetKey];
    });
    return row;
  }

  /**
   * An imported patient is valid if he provides the mandatory values
   */
  getInvalidValues(patient: Patient): string[] {
    // Set the "validity" of the patient. A patient is not "valid" if it does not have the mandatory values.
    const invalidValues: string[] = [];
    for (let i = 0; i < mandatoryValues.length; i++) {
      const valueKey = mandatoryValues[i];
      if (!patient[valueKey]) {
        // Add the french name of the value
        correspondenceTable.forEach(function (patientKey, frenchName) {
          if (patientKey == valueKey) {
            invalidValues.push(frenchName);
          }
        });
      }
    }
    return invalidValues;
  }

  private getPatientStructured(csvRow: CsvPatientRow) {
    const { firstName, birthName } = csvRow;
    const birthday = getDateFromString(csvRow.birthday);
    const gender = csvRow.gender == "F" ? "female" : "male";
    const lastName = csvRow.lastName || csvRow.birthName;
    const { email } = csvRow;
    const { address, postcode, city, moreAddressInformation } = csvRow;
    const phone = getPhoneStr(csvRow.phone);
    const { ippNumber, insuredSocialSecurityNumber } = csvRow;
    const insStatus = InsStatus.Provisional;

    const healthCentre = this.store.peekRecord(
      "health-centre",
      csvRow.healthCentre
    );

    const medics: MedicModel[] = [];
    const medicsIds = csvRow.medics ? csvRow.medics?.split(",") : undefined;
    medicsIds?.forEach(medicId => {
      const medic = this.store.peekRecord("medic", medicId) as MedicModel;
      if (medic) {
        medics.push(medic);
      }
    });

    return {
      firstName,
      lastName,
      birthName,
      birthday,
      gender,
      address,
      postcode,
      city,
      moreAddressInformation,
      email,
      phone,
      ippNumber,
      insuredSocialSecurityNumber,
      insStatus,
      healthCentre,
      medics
    };
  }

  private getMedicalHistoryStructured(patient: Patient, csvRow: CsvPatientRow) {
    const occurredAt = new Date();
    const transplantDate = csvRow.transplantDate
      ? getDateFromString(csvRow.transplantDate)
      : undefined;
    const { nephropatie, donorType } = csvRow;
    const mrcStage = getMrcStage(csvRow.mrcStage);

    return {
      patient,
      occurredAt,
      transplantDate,
      nephropatie,
      donorType,
      mrcStage
    };
  }
}

function getPhoneStr(phoneNumber: string): string {
  if (phoneNumber) {
    const str = phoneNumber.toString().replace(/ /g, "");
    return str.length == 9 ? "0" + str : str;
  }
  return phoneNumber;
}

function getMrcStage(mrc: string | undefined): string | null {
  if (mrc) {
    return "stage-" + mrc;
  }
  return null;
}
