import Route from "@ember/routing/route";
import Patient from "core/models/patient";

interface Model {
  patient: Patient;
}

export default class DocumentationPageRoute extends Route {
  model(): Model {
    return this.modelFor("medics-web.protected.patients.patient") as Model;
  }
}
