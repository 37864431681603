import Controller from "@ember/controller";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { SemeiaPatientSession } from "core/services/session";
import FlashMessagesService from "ember-cli-flash/services/flash-messages";

export default class PatientWebController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare session: SemeiaPatientSession;

  @tracked isPasswordModalShown = this._shouldShowPasswordModal;

  private get _shouldShowPasswordModal(): boolean {
    return Boolean(
      this.session.authenticatedAtThisSession &&
        this.session.patient.shouldChangePassword
    );
  }
}
