import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { ModelFrom } from "parog-web";
import Route from "./route";

export default class PatientController extends Controller {
  queryParams = ["tab"];
  @tracked tab = "alert";

  declare model: ModelFrom<Route>;
}
