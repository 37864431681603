import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";

export default class PWResultsTableMeasurementsRoute extends Route {
  @service declare store: Store;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    await Promise.all([
      this.store.query("weight-measured-journal-event", {
        filter: { patient_id: patient.id }
      }),
      this.store.query("height-measured-journal-event", {
        filter: { patient_id: patient.id }
      }),
      this.store.query("abdominal-area-declared-journal-event", {
        filter: { patient_id: patient.id }
      })
    ]);

    return patient;
  }
}
