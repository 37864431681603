import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { ModelFrom } from "parog-web";
import type PrescriptionsRoute from "./route";

export default class PrescriptionsController extends Controller {
  declare model: ModelFrom<PrescriptionsRoute>;

  @tracked filter;
}
