import { action } from "@ember/object";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import * as Sentry from "@sentry/browser";
import { loadVitalSigns } from "core/data/vital-signs";
import Patient from "core/models/patient";
import TherapeuticalArea from "core/models/therapeutical-area";
import CableService from "core/services/cable";
import ConfigurationService from "core/services/configuration";
import FetcherService from "core/services/fetcher";
import type { SemeiaPatientSession } from "core/services/session";
import TermsOfUseService from "core/services/terms-of-use";
import {
  getEnvironmentName,
  getEnvironmentSuffix
} from "core/utils/runtime-environment";

export default class PatientWebProtectedRoute extends Route {
  @service declare configuration: ConfigurationService;
  @service declare session: SemeiaPatientSession;
  @service declare termsOfUse: TermsOfUseService;
  @service declare fetcher: FetcherService;
  @service declare router: RouterService;
  @service declare cable: CableService;

  async beforeModel(transition) {
    if (this.session.requireAuthentication(transition, "patient-web.login")) {
      // FIXME: now that we have our own session service, this logic could be moved in the service
      if (!this.session.patient) {
        this.router.transitionTo("patient-web.login");
      }
      this.checkParogInUrl();
    }
    return;
  }

  async model(): Promise<Patient | void> {
    try {
      const obsRequestOnPatientId = this.fetcher.loadRecords("loinc", {
        filter: { patient_id: this.session.patient.id }
      });

      const obsRequestOnLoinc = this.fetcher.loadRecords("loinc", {
        filter: {
          loinc: (
            this.session.patient.therapeuticalArea as TherapeuticalArea
          ).defaultBioExams.map(exam => exam.id)
        }
      });

      const orderRequest = this.fetcher.loadRecords("bio-exam-order", {
        filter: { patient_id: this.session.patient.id }
      });

      const results = await Promise.all([
        obsRequestOnPatientId,
        obsRequestOnLoinc,
        orderRequest,
        this._patientPromise()
      ]);

      loadVitalSigns();

      const patient = results[3];

      // This needs to stay here as we need the JEs to be accessible in many nested routes
      await this.fetcher.loadRecords(
        "treatment-prescription-changed-journal-event",
        {
          filter: {
            patient_id: patient.id,
            most_recent: ""
          },
          backgroundReload: false
        }
      );

      await this.configuration.fetchConfigurationsForPatient(patient);

      return patient;
    } catch (e) {
      Sentry.captureException(e);
      this.session.invalidate();
    }
  }

  async afterModel(
    model: Patient,
    transition: ReturnType<RouterService["transitionTo"]>
  ): Promise<Patient | void> {
    // If the patient has not accepted the latest Terms of Use,
    // redirect to that page until he accepts it.
    this.termsOfUse.redirectToAcceptTermsOfUseIfNeeded(
      model.acceptedTermsOfUseVersion,
      transition
    );
    await this.cable.openSocket();
    this.cable.subscribeToReloadChannel(model);
  }

  _patientPromise(): Promise<Patient> {
    return this.fetcher.loadRecord("patient", this.session.patient.id, {
      include: [
        "medics",
        "health-centre",
        "health-centre.surveys",
        "health-centre.surveys.questions",
        "patient-enrolled-journal-event",
        "patient-disenrolled-journal-event",
        "comorbidities-and-allergies-changed-journal-event",
        "compound-survey-frequencies-changed-journal-event",
        "crisis-plan-declared-journal-event",
        "digital-care-declared-journal-event",
        "jobs-declared-journal-event",
        "lifestyle-declared-journal-event",
        "medical-history-changed-journal-event",
        "personal-notes-changed-journal-event"
      ].join(",")
    });
  }

  deactivate(): void {
    this.cable.unsubscribeToReloadChannel(
      this.modelFor("patient-web.protected") as Patient
    );
  }

  // If the host is different than the product associated to the patient
  // (for example, using nephrowise-staging when the patient is associated to
  // mentalwise), we automatically redirect to the correct host, considering environment
  @action
  checkParogInUrl() {
    if (["local", "development"].includes(getEnvironmentName())) {
      return;
    }

    const host = window.location.host;
    const [subdomain, ...rest] = host.split(".");
    const parog = subdomain.split("-")[0];

    const productName = this.session.patient.healthCentre?.productName;

    if (
      ![
        "nephrowise",
        "pneumowise",
        "oncowise",
        "mentalwise",
        "rhumatowise"
      ].includes(productName)
    ) {
      return;
    }

    if (productName && parog != productName) {
      const queryParams = new URL(window.location.href).searchParams;
      if (!queryParams.has("access_token")) {
        queryParams.set("access_token", this.session.access_token as string);
      }
      queryParams.set("redirectedFrom", parog);

      window.location.replace(
        `https://${productName}${getEnvironmentSuffix()}.${rest.join(".")}${
          window.location.pathname
        }?${queryParams.toString()}`
      );
    }
  }
}
