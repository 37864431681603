import Store from "@ember-data/store";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { SemeiaMedicSession } from "core/services/session";
import sortBySortProperties from "core/utils/sort-by-sort-properties";
import IntlService from "ember-intl/services/intl";
import MedicsWebTabController from "../tab/controller";
import { MedicsWebIndexPatient, MedicsWebTabModel } from "../tab/route";

export default class MedicsWebIndexPatientListController extends MedicsWebTabController {
  @service declare intl: IntlService;
  @service declare session: SemeiaMedicSession;
  @service declare store: Store;

  @tracked sortCriterion = "lastName";
  @tracked sortOrder = "asc";

  get title(): string {
    const translationKey = (this.model as MedicsWebTabModel).filter
      .translationKey;
    if (!translationKey) return "Patients";

    if (translationKey?.name == this.session.medic.fullName) {
      return `Mes patients ${this.intl
        .t("filters." + translationKey.status)
        .toLowerCase()}`;
    }

    return `Patients ${this.intl
      .t("filters." + translationKey.status)
      .toLowerCase()} suivis par ${
      translationKey.name ? translationKey.name : "tous mes centres"
    }`;
  }

  get sortedPatients(): MedicsWebIndexPatient[] {
    return sortBySortProperties(
      (this.model as MedicsWebTabModel).patients.slice(),
      [
        ["isDisenrolled", "asc"],
        [this.sortCriterion, this.sortOrder]
      ]
    );
  }

  @action
  onSortCriterionChange(sortId: string, isAscending: boolean): void {
    this.preserveScrollPosition = true;
    this.sortCriterion = sortId;
    this.sortOrder = isAscending ? "asc" : "desc";
  }
}
