import { get } from "@ember/object";
import Component from "@glimmer/component";
import { BufferedChangeset } from "ember-changeset/types";

interface Args {
  propertyName: string;
  changeset: BufferedChangeset;
}

export default class extends Component<Args> {
  // Even if there are multiple errors related to `this.propertyName`, we show only one validation at a time
  // (a validation describes what was wrong with the value set on the changeset)
  get validation() {
    if (!this.args.propertyName || !this.args.changeset) return;

    const error = get(this.args.changeset.error, this.args.propertyName);

    if (!error) return undefined;

    return Array.isArray(error.validation)
      ? error.validation[0]
      : error.validation;
  }
}
