import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import BioExamFrequencyChangedJournalEvent from "core/models/bio-exam-frequency-changed-journal-event";
import JournalEventModel from "core/models/journal-event";
import ErrorsService from "core/services/errors";
import { Changeset, EmberChangeset } from "ember-changeset";
import lookupValidator from "ember-changeset-validations";
import FlashMessagesService from "ember-cli-flash/services/flash-messages";
import Route from "./route";

export default class MedicsWebPatientsPatientHealthcarePlanController extends Controller {
  declare model: ReturnType<Route["model"]>;

  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare errors: ErrorsService;

  @action
  createBioExamFrequency() {
    const model = this.store.createRecord(
      "bio-exam-frequency-changed-journal-event",
      {
        occurredAt: new Date(),
        source: "wise"
      }
    );

    return Changeset(
      model,
      lookupValidator(BioExamFrequencyChangedJournalEvent.validations),
      BioExamFrequencyChangedJournalEvent.validations
    );
  }

  @action
  onDestroyBioExamFrequency(index: number): void {
    const bioExamFrequencyToDestroy =
      this.model.bioExamFrequencyChangesets[index];

    const model = bioExamFrequencyToDestroy.data as JournalEventModel;
    if (bioExamFrequencyToDestroy.isNew) {
      model.destroyRecord();
    } else {
      this.model.toDestroyModels.push(model);
    }
  }

  @action
  onUpdateBioExamFrequencies(items: Array<EmberChangeset>) {
    this.model.bioExamFrequencyChangesets = items.map((item, index) => {
      item.set("index", index);
      return item;
    });
  }

  @action
  onBackPressed() {
    this.router.transitionTo("medics-web.protected.patients.patient.dashboard");
  }

  @action
  async onSubmit(event) {
    event.preventDefault();

    if (await this.model.save()) {
      this.flashMessages.success("Information bien enregistrée. Merci !");

      this.model.bioExamFrequencyChangesets = [];

      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard"
      );
    } else {
      this.errors.displayError(
        "Veuillez corriger les erreurs avant de sauvegarder"
      );
    }
  }
}
