import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { validateFormat } from 'ember-changeset-validations/validators';
import WrForm from 'ember-wiser/components/wr/form';
import WrLinkTo from 'ember-wiser/components/wr/link-to';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
export interface Signature {
}
interface FormData {
    username?: string;
    password?: string;
}
export default class V2MedicLoginFormComponent extends Component<Signature> {
    d: FormData = {};
    validations = {
        username: validateFormat({
            type: 'email'
        })
    };
    get changeset() {
        return Changeset(this.d, lookupValidator(this.validations), this.validations) as FormData;
    }
    h = (data1: FormData)=>{
        console.log(data1.username);
    };
    static{
        template(`
    <WrForm
      @data={{this.changeset}}
      @onSubmit={{this.h}}
      class='flex flex-col items-stretch py-4 gap-4 w-[355px] mx-auto'
      as |form|
    >
      <h3 class='wr primary header text-center'>
        Se connecter
      </h3>

      <div class='my-2'>
        <form.Input
          @name='username'
          aria-label='Votre adresse e-mail'
          placeholder='Votre adresse e-mail'
          autocomplete='username'
        />
      </div>

      <div class='my-2'>
        <form.Input
          @name='password'
          type='password'
          aria-label='Votre mot de passe'
          placeholder='Votre mot de passe'
          autocomplete='current-password'
        />
      </div>

      <form.SubmitButton @icon='Arrow' class='self-center'>Se connecter</form.SubmitButton>

      <WrLinkTo @route='v2.medic-password-forgotten' class='self-center'>
        J’ai oublié mon mot de passe
      </WrLinkTo>
    </WrForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
