import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { ModelFrom } from "parog-web";
import type OkChimioRoute from "./route";

export default class OkChimioController extends Controller {
  declare model: ModelFrom<OkChimioRoute>;

  @tracked filter;
}
