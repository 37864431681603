import { RouteInfo } from "@ember/routing/-internals";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import { getItemFromLocalStorage } from "core/utils/use-local-storage";

export const patientDashboardLastRouteKey = "last-patient-dashboard-route";

export default class MedicsWebPatientDashboardMainRoute extends Route<Patient> {
  @service declare router: RouterService;

  afterModel(model: Patient, transition: Transition): void {
    if (!model.isEnrolled) {
      return;
    }
    if (!this.shouldRedirect((transition.to as RouteInfo).name)) {
      return;
    }

    const lastRoute = getItemFromLocalStorage(patientDashboardLastRouteKey);
    if (lastRoute) {
      this.router.transitionTo(lastRoute);
    } else {
      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard.main"
      );
    }
  }

  /*
   Return false for some routes, for instance when being redirected to a results table
   We don't want to be redirected
   */
  shouldRedirect(targetRoute: string) {
    // when explicitely visiting this route we don't want to be redirected
    if (targetRoute == "medics-web.protected.patients.patient.dashboard.main") {
      return false;
    }

    // when explicitely visiting this route we don't want to be redirected
    if (
      targetRoute ==
      "medics-web.protected.patients.patient.dashboard.bio-results.summary"
    ) {
      return false;
    }

    // when explicitely visiting a results table route we don't want to be redirected
    if (targetRoute.includes("dashboard.main.results-table")) {
      return false;
    }

    // when explicitely visiting a FORC as modale route we don't want to be redirected
    if (targetRoute.includes("dashboard.main.feature.component")) {
      return false;
    }

    return true;
  }
}
