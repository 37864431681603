import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import WrModalContent from 'ember-wiser/components/wr/modal/content';
import WrDivider from 'ember-wiser/components/wr/divider';
import WrButton from 'ember-wiser/components/wr/button';
import WrInputControl from 'ember-wiser/components/wr/form/controls/input';
import WrLinkTo from 'ember-wiser/components/wr/link-to';
export default RouteTemplate(template(`
  <WrModalContent @targetModal='modal-1' @title='Connexion patient'>
    <form class='flex flex-col items-center py-4 gap-4 w-full'>
      <h3 class='wr primary header text-center'>
        Recevoir un lien de connexion
        <br />
        par e-mail ou SMS
      </h3>

      <WrInputControl
        aria-label='Votre e-mail ou numéro de téléphone'
        type='text'
        placeholder='Votre e-mail ou numéro de téléphone'
        class='max-w-[355px]'
      />

      <WrButton type='submit' @icon='ArrowRight'>
        Recevoir le lien
      </WrButton>
    </form>

    <div class='flex flex-col items-center py-4 gap-4 w-full'>
      <WrDivider>ou</WrDivider>

      <WrLinkTo @route='v2.patient-password-login'>
        Je me connecte avec mon mot de passe
      </WrLinkTo>

      <WrLinkTo @route='v2.patient-password-forgotten'>
        Je crée mon mot de passe
      </WrLinkTo>
    </div>
  </WrModalContent>
`, {
    eval () {
        return eval(arguments[0]);
    }
}));
