import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { SemeiaMedicSession } from "core/services/session";
import Medic from "core/models/medic";

export default class MedicsWebTabController extends Controller {
  preserveScrollPosition;

  queryParams = ["selectedFilter"];
  @tracked selectedFilter;

  @service declare router: RouterService;
  @service declare store: Store;
  @service declare session: SemeiaMedicSession;

  @action
  onFilterChange(filter: string): void {
    // Triggers a refresh of the model.
    this.router.transitionTo({
      queryParams: { selectedFilter: filter, refreshModel: true }
    });
  }

  get medics(): Medic[] {
    const orderedMedics = this.store
      .peekAll("medic")
      .filter(medic => medic.hasRole("doctor", "nurse", "nurse_lead"))
      .sort((a, b) => a.fullName.localeCompare(b.fullName))
      .filter(medic => medic != this.session.medic);

    //Inject the current medic as the first element of the medics array
    orderedMedics.splice(0, 0, this.session.medic);

    return orderedMedics;
  }
}
