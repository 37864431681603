import Route from "@ember/routing/route";
import RouteInfo from "@ember/routing/route-info";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import NavigationService from "core/services/navigation";
import SemeiaSessionService from "core/services/session";
import logError from "core/utils/log-error";

export default class MedicsWebRoute extends Route {
  @service declare session: SemeiaSessionService;
  @service declare router: RouterService;
  @service declare navigation: NavigationService;

  beforeModel(transition: Transition): void {
    const queryParams = (transition.to as RouteInfo).queryParams;

    if (queryParams.embedded !== undefined) {
      this.navigation.embedded = queryParams.embedded !== "false";
    }

    // Login the medic from the token in params
    if (!this.session.isAuthenticated && queryParams.access_token) {
      this.session.attemptedTransition = transition;
      this.session
        .authenticate("authenticator:medic-implicit", queryParams)
        .catch(e => {
          logError(e, "mw.auth");
          this.router.transitionTo("medics-web.login");
        });
    }
  }
}
