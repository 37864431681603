import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import BaseControllerComponent from "core/components/forc/abstract/controller/base-controller-component";
import FormAction from "core/components/forc/form-action";

export default class PatientFeatureComponentController extends BaseControllerComponent {
  @service declare router: RouterService;

  @action
  transitionToDashboard() {
    if (this.callbackUrl) {
      this.router.transitionTo(this.callbackUrl);
    } else {
      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard"
      );
    }
  }

  get formAction() {
    return FormAction.New;
  }
}
