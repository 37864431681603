import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";

import Patient from "core/models/patient";

export default class ResultsTableDas28Route extends Route {
  @service declare store: Store;

  async model() {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    await Promise.all([
      this.store.query("das28-crp-computed-journal-event", {
        filter: { patient_id: patient.id }
      }),
      this.store.query("das28-vs-computed-journal-event", {
        filter: { patient_id: patient.id }
      }),
      this.store.query("das28-cdai-computed-journal-event", {
        filter: { patient_id: patient.id }
      }),
      this.store.query("das28-sdai-computed-journal-event", {
        filter: { patient_id: patient.id }
      })
    ]);

    return patient;
  }
}
