import Store from "@ember-data/store";
import { action } from "@ember/object";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type AbilitiesService from "ember-can/services/abilities";

import AppUsageConsentUpdatedJournalEvent from "core/models/app-usage-consent-updated-journal-event";
import DataSharingConsentUpdatedJournalEvent from "core/models/data-sharing-consent-updated-journal-event";
import Patient, { ContactType } from "core/models/patient";
import PatientEnrolledJournalEvent from "core/models/patient-enrolled-journal-event";
import TlsConsentUpdatedJournalEvent from "core/models/tls-consent-updated-journal-event";
import ProIrcStatusUpdatedJournalEvent from "pro-irc/models/pro-irc-status-updated-journal-event";

/**
 * This model encapsulates some journal events:
 *
 * - a PatientEnrolledJournalEvent
 * - a AppUsageConsentUpdatedJournalEvent
 * - a DataSharingConsentUpdatedJournalEvent
 * ...
 *
 * Thanks to this model's `save()` method, we can wrap it inside a changeset
 * to be used by a form.
 * */
export class Enrollment {
  @tracked enrollment: PatientEnrolledJournalEvent;
  @tracked tls: TlsConsentUpdatedJournalEvent;
  @tracked appUsage: AppUsageConsentUpdatedJournalEvent;
  @tracked dataSharing: DataSharingConsentUpdatedJournalEvent;
  @tracked contactPreferences: Array<ContactType>;
  @tracked proIrc: ProIrcStatusUpdatedJournalEvent;

  get followUpReason() {
    return this.enrollment.followUpReason;
  }

  set followUpReason(value) {
    this.enrollment.followUpReason = value;
  }

  get email() {
    return this.enrollment.patient.email;
  }

  set email(value) {
    this.enrollment.patient.email = value;
  }

  get phone() {
    return this.enrollment.patient.phone;
  }

  set phone(value) {
    this.enrollment.patient.phone = value;
  }

  /** True if the `save()` Promise was successful */
  @tracked isNew = true;

  constructor(patient: Patient, store: Store) {
    this.enrollment = store.createRecord("patient-enrolled-journal-event");
    this.tls = store.createRecord("tls-consent-updated-journal-event");
    this.proIrc = store.createRecord("pro-irc-status-updated-journal-event", {
      proIrcStartDate: new Date(),
      included: true
    });
    this.appUsage = store.createRecord(
      "app-usage-consent-updated-journal-event"
    );
    this.dataSharing = store.createRecord(
      "data-sharing-consent-updated-journal-event"
    );
    this.contactPreferences = this.isNew
      ? ["phone"]
      : patient.contactPreferences;

    this.fullyAgreed = true;
    this.occurredAt = new Date();
    this.patient = patient;

    if (patient.healthCentre.preTransplant) {
      this.tls.agreed = false;
      this.appUsage.agreed = false;
    }
  }

  async save(): Promise<void> {
    this.enrollment.patient.contactPreferences = this.contactPreferences;
    await this.enrollment.patient.save();

    const unloadTlsConsentCondition = v =>
      v instanceof TlsConsentUpdatedJournalEvent &&
      !this.enrollment.followUpReason.startsWith("nephro");
    const unloadProIrcCondition = v =>
      v instanceof ProIrcStatusUpdatedJournalEvent &&
      (!this.enrollment.patient.healthCentre.proIrc ||
        this.enrollment.followUpReason != "nephro-mrc");

    await Promise.all(
      this.records
        .map(v => {
          if (unloadTlsConsentCondition(v) || unloadProIrcCondition(v)) {
            v.unloadRecord();
            return;
          }
          return v.save();
        })
        .filter(x => x)
    );
    this.isNew = false;
  }

  /** The wrapped records */
  get records(): [
    Enrollment["tls"],
    Enrollment["appUsage"],
    Enrollment["dataSharing"],
    Enrollment["enrollment"],
    Enrollment["proIrc"]
  ] {
    return [
      this.tls,
      this.appUsage,
      this.dataSharing,
      this.enrollment,
      this.proIrc
    ];
  }

  /** Convenience setter to easily set `aggred` on consent models */
  set fullyAgreed(flag: boolean) {
    this.tls.agreed = this.appUsage.agreed = this.dataSharing.agreed = flag;
  }

  /** Convenience setter to easily set `occurredAt` on wrapped JEs */
  set occurredAt(date: Date) {
    this.records.map(v => (v.occurredAt = date));
  }

  /** Convenience setter to easily set `patient` on wrapped JEs */
  set patient(patient: Patient) {
    this.records.map(v => (v.patient = patient));
  }
}

export default class PatientInclusionRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service router;
  @service declare store: Store;
  @tracked private declare _model: Enrollment;

  beforeModel(): void {
    if (this.abilities.cannot("enroll patient")) {
      this.router.transitionTo("medics-web.protected");
    }
  }

  model(): Enrollment {
    const model = new Enrollment(
      this.modelFor("medics-web.protected.patients.patient") as Patient,
      this.store
    );

    // Stash the model for further access in `willTransition`
    this._model = model;

    return model;
  }

  @action
  willTransition(): void {
    if (this._model.isNew) this.teardownModel();
  }

  private teardownModel() {
    this._model.records.forEach(record => record.unloadRecord());
  }
}
