import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class MedicsWebPatientEducationLearnRoute extends Route {
  @service declare fetcher: FetcherService;

  afterModel(model: Patient) {
    const articles = this.fetcher.loadRecords("learning-article", {
      filter: {
        health_centre: model.healthCentre.id
      }
    });

    const sections = this.fetcher.loadRecord(
      "health-centre",
      model.healthCentre.id,
      { include: "learning-sections" }
    );

    return Promise.all([articles, sections]);
  }
}
