import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class PatientWebDashboardLearnRoute extends Route {
  @service declare fetcher: FetcherService;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    if (patient.isRenalTransplanted) {
      await this.fetcher.loadRecords(
        "therapeutic-assistance-session-completed-journal-event",
        {
          filter: {
            patient_id: patient.id
          },
          backgroundReload: false
        }
      );
    }

    return patient;
  }

  afterModel(model: Patient) {
    const articles = this.fetcher.loadRecords("learning-article", {
      filter: {
        health_centre: model.healthCentre.id
      }
    });

    const sections = this.fetcher.loadRecord(
      "health-centre",
      model.healthCentre.id,
      { include: "learning-sections" }
    );

    return Promise.all([articles, sections]);
  }
}
