import Store from "@ember-data/store";
import type ModelRegistry from "@ember-data/types/registries/model";
import { action } from "@ember/object";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

import ErrorsService from "core/services/errors";
import ParogService from "core/services/parog";
import { SemeiaMedicSession } from "core/services/session";
import type AbilitiesService from "ember-can/services/abilities";

export default class NewPatientRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare errors: ErrorsService;
  @service declare parog: ParogService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare session: SemeiaMedicSession;

  beforeModel() {
    if (this.abilities.cannot("create patient")) {
      this.router.transitionTo("medics-web.protected");
    }
  }

  model() {
    const medic: ModelRegistry["medic"] = this.session.medic;

    const attrs: Record<string, unknown> = {
      medics: [medic],
      insStatus: "provisional"
    };

    if (medic.healthCentres.length == 1)
      attrs.healthCentre = medic.primaryHealthCenter;

    return this.store.createRecord("patient", attrs);
  }

  @action
  willTransition() {
    const controller = this.controllerFor("medics-web.protected.patients.new");
    const model = controller.model as ReturnType<NewPatientRoute["model"]>;

    if (model.get("isNew")) model.destroyRecord();
  }
}
