import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import BioExamResultsService from "core/services/bio-exam-results";
import { getItemFromLocalStorage } from "core/utils/use-local-storage";

export const medicsWebIndexLastRouteKey = "last-medics-web-index-route";

export default class MedicsWebIndexRoute extends Route {
  @service declare bioExamResults: BioExamResultsService;
  @service declare router: RouterService;

  async model(): Promise<void> {
    await this.bioExamResults.loadBioExamDraftCount();
  }

  afterModel(_model: unknown, transition: Transition): Promise<unknown> | void {
    if (transition.to?.name === "medics-web.protected.index.index") {
      const lastRoute = getItemFromLocalStorage(medicsWebIndexLastRouteKey);
      if (lastRoute) {
        this.router.transitionTo(lastRoute);
      } else {
        this.router.transitionTo(
          "medics-web.protected.index.alerts-and-notifications"
        );
      }
    }
  }
}
