import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import BioExamReportTemplate from "core/models/bio-exam-report-template";
import Patient from "core/models/patient";
import { sortBy } from "core/utils/sort";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import { DS } from "ember-data";

export default class BioPrescriptionTemplateSelectionRoute extends Route {
  @service declare store: Store;

  model() {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    // Get the list of available report templates configured for the HealthCentre filtered for the patient's FuR
    const reportTemplates = this.store.peekAll(
      "bio-exam-report-template"
    ) as DS.RecordArray<BioExamReportTemplate>;

    const healthCentreReportTemplates = reportTemplates.filter(
      template =>
        template.prescriptionConfiguration.healthCentre ==
          patient.healthCentre &&
        (template.followUpReasons.includes(patient.followUpReason) ||
          template.followUpReasons.includes("all"))
    );

    // Get the five last prescription of the patient
    const lastBioExamPrescribedJes = patient.bioExamPrescribedJournalEvents
      .toSorted(sortBy("createdAt", "desc"))
      .slice(0, 5);

    return {
      patient: patient,
      healthCentreReportTemplates: healthCentreReportTemplates,
      lastBioExamPrescribedJes: lastBioExamPrescribedJes
    };
  }
}
