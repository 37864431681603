import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

export default class SetPasswordController extends Controller {
  @service declare router: RouterService;

  queryParams = ["token"];
  token: string | undefined;

  @action
  onSuccess() {
    this.router.transitionTo("medics-web.login");
  }

  @action
  onFail() {
    this.router.transitionTo("medics-web.forgot-password.send-instructions");
  }
}
