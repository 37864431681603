import Controller from "@ember/controller";
import { service } from "@ember/service";
import NavigationService from "core/services/navigation";
import ParogService from "core/services/parog";
import { ModelFrom } from "parog-web";
import MedicsWebPatientDashboardMainRoute from "./route";

export default class PatientDashboardController extends Controller {
  declare model: ModelFrom<MedicsWebPatientDashboardMainRoute>;
  @service declare navigation: NavigationService;
  @service declare parog: ParogService;

  preserveScrollPosition = true;
}
