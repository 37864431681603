import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";
import { SemeiaMedicSession } from "core/services/session";

export default class GlobalMedicalSynthesisDownloadRoute extends Route {
  @service declare session: SemeiaMedicSession;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare fetcher: FetcherService;

  beforeModel() {
    if (
      !this.session.medic.therapeuticalAreaIds.some(
        therapeuticalArea => therapeuticalArea == "diabeto"
      )
    )
      this.router.transitionTo("medics-web.protected.index");
  }

  async model(): Promise<Patient[]> {
    const patients = await this.fetcher.loadRecords("patient", {
      filter: { enrolled: "", medics: [this.session.medic.id] }
    });

    await this.fetcher.loadRecords("patient-enrolled-journal-event", {
      filter: { patient_id: patients.map(p => p.id), most_recent: "" }
    });

    return patients;
  }
}
