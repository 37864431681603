import Store from "@ember-data/store";
import Controller from "@ember/controller";
import Route from "@ember/routing/route";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import MedicModel from "core/models/medic";
import SemeiaSessionService from "core/services/session";
import type AbilitiesService from "ember-can/services/abilities";

export default class MedicsNewRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service router;
  @service declare session: SemeiaSessionService;
  @service declare store: Store;

  declare currentModel: MedicModel;

  beforeModel(): void {
    if (this.abilities.cannot("create medic")) {
      this.router.transitionTo("medics-web.protected");
    }
  }

  async model(): Promise<MedicModel> {
    const medic = this.store.createRecord("medic");
    if (
      ["cardio", "hepato"].some(element =>
        this.session.medic?.therapeuticalAreaIds.includes(element)
      )
    )
      return medic;

    medic.days = ["monday", "tuesday", "wednesday", "thursday", "friday"];
    medic.preferredTime = 9;
    return medic;
  }

  deactivate(): void {
    const model = this.modelFor(this.routeName) as MedicModel;
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      model.isNew &&
      // 'deleteRecord' can fail if the store is currently destroying during a test
      !model.store.isDestroying
    ) {
      model.deleteRecord();
    }
  }

  setupController(
    controller: Controller,
    model: unknown,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.set(
      "healthCentres" as keyof Controller,
      this.session.medic?.healthCentres
    );
  }
}
