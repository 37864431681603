import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { SemeiaMedicSession } from "core/services/session";

export default class LogoutRoute extends Route {
  @service declare session: SemeiaMedicSession;
  @service declare router: RouterService;

  async beforeModel(): Promise<void> {
    await this.session.invalidate();
    this.router.transitionTo("medics-web.login");
  }
}
