import { getOwner } from "@ember/-internals/owner";
import { service } from "@ember/service";
import * as Sentry from "@sentry/ember";
import SemeiaSessionService from "core/services/session";
import Base from "ember-simple-auth/authenticators/base";
import fetch from "fetch";
import ApplicationAdapter from "parog-web/adapters/application";
import { setupSentry } from "parog-web/authenticators/utils/sentry";

export default class MedicImplicitAuthenticator extends Base {
  @service declare session: SemeiaSessionService;

  tokenEndpoint = "/api/v1/authorize_medic_token";
  applicationAdapter = getOwner(this)?.lookup(
    "adapter:application"
  ) as ApplicationAdapter;

  async restore(data) {
    if (!this._isTokenValid(data.access_token)) {
      setupSentry();
      throw "Medic Implicit Authenticator - Invalid token";
    }

    setupSentry(data, "Medic");

    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async authenticate(requestData) {
    // If the medic is already authenticated, keep him logged in
    // with the previous access token if the new one is already expired
    if (
      this.session.isAuthenticated &&
      this.session.data.authenticated.as === "medic" &&
      !this._isTokenValid(requestData.access_token)
    ) {
      return this.session.data.authenticated;
    }

    await fetch(this.tokenEndpoint, {
      method: "POST",
      headers: Object.assign(
        {
          "Content-Type": "application/json"
        },
        this.applicationAdapter.headers
      ),
      body: JSON.stringify(requestData)
    });

    const data = this._extractDataFromToken(requestData.access_token);
    if (!data) return;
    setupSentry(data, "Medic");

    this.session.authenticatedAtThisSession = true;
    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async invalidate() {
    setupSentry();
  }

  _isTokenValid(token) {
    try {
      const payload = this._decodeToken(token);
      const expirationTimestamp = payload.exp * 1000;
      const todayTimestamp = new Date().getTime();

      return todayTimestamp < expirationTimestamp;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  }

  _extractDataFromToken(token) {
    try {
      const payload = this._decodeToken(token);
      return {
        id: payload.sub as string,
        uuid: payload.uuid as string,
        role: payload.role as string,
        as: "medic",
        access_token: token
      };
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  _decodeToken(token) {
    return JSON.parse(window.atob(token.split(".")[1]));
  }
}
