import { action } from "@ember/object";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import BioExamFrequencyChangedJournalEvent from "core/models/bio-exam-frequency-changed-journal-event";
import JournalEventModel from "core/models/journal-event";
import Patient from "core/models/patient";
import ErrorsService from "core/services/errors";
import { sortBy } from "core/utils/sort";
import { Changeset, EmberChangeset } from "ember-changeset";
import lookupValidator from "ember-changeset-validations";

class HealthcarePlan {
  @tracked bioExamFrequencyChangesets: Array<EmberChangeset>;
  toDestroyModels: Array<JournalEventModel> = [];
  readonly patient: Patient;

  get mostRecent(): BioExamFrequencyChangedJournalEvent | undefined {
    return this.bioExamFrequencyChangesets
      .filter(changeset => !changeset.isNew)
      .toSorted(sortBy("createdAt"))
      .at(-1)?.data as BioExamFrequencyChangedJournalEvent | undefined;
  }

  constructor({
    patient,
    bioExamFrequencyJEs
  }: {
    patient: Patient;
    bioExamFrequencyJEs: Array<BioExamFrequencyChangedJournalEvent>;
  }) {
    this.patient = patient;
    this.bioExamFrequencyChangesets = bioExamFrequencyJEs
      .map(je =>
        Changeset(
          je,
          lookupValidator(BioExamFrequencyChangedJournalEvent.validations),
          BioExamFrequencyChangedJournalEvent.validations
        )
      )
      .sort(function (a: EmberChangeset, b: EmberChangeset): number {
        return a.get("index") - b.get("index");
      });
  }

  get isDirty(): boolean {
    for (const je of this.bioExamFrequencyChangesets) {
      if (je.isDirty) return true;
    }
    return false;
  }

  async validate(): Promise<void> {
    const promises = this.bioExamFrequencyChangesets.map(async changeset => {
      await changeset.validate();
    });
    await Promise.all(promises);
  }

  get isValid(): boolean {
    for (const changeset of this.bioExamFrequencyChangesets) {
      if (changeset.get("isInvalid")) return false;
    }
    return true;
  }

  async save(): Promise<boolean> {
    await this.validate();

    if (this.isValid) {
      await Promise.all(
        this.bioExamFrequencyChangesets
          .map(changeset => {
            if (changeset.isDirty) {
              changeset.set("occurredAt", new Date());
              changeset.set("source", "wise"); //update the source if the record is changed from Wise
              changeset.set("patient", this.patient);
              return changeset.save();
            }
            return undefined;
          })
          .filter(Boolean)
      );

      await Promise.all(
        this.toDestroyModels.map(journalEventModel => {
          return journalEventModel.archive();
        })
      );

      return true;
    }
    return false;
  }

  reset(): void {
    this.bioExamFrequencyChangesets.forEach(changeset => {
      if (changeset.isNew) {
        (changeset.data as JournalEventModel).destroyRecord();
      }
    });
    this.bioExamFrequencyChangesets = [];
  }
}

export default class MedicsWebProtectedPatientsPatientHealthcarePlanRoute extends Route<HealthcarePlan> {
  @service declare errors: ErrorsService;

  model(): HealthcarePlan {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;
    return new HealthcarePlan({
      patient,
      bioExamFrequencyJEs: patient.bioExamFrequencyChangedJournalEvents
    });
  }

  @action
  willTransition(transition) {
    const controller = this.controllerFor(
      "medics-web.protected.patients.patient.healthcare-plan"
    );
    if ((controller.model as HealthcarePlan).isDirty) {
      transition.abort();

      this.errors.showPendingChangesetError().then(result => {
        if (result.value) {
          (controller.model as HealthcarePlan).reset();
          transition.retry();
        }
      });
    }
  }
}
