import { action } from "@ember/object";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { default as Medic, default as MedicModel } from "core/models/medic";
import Patient from "core/models/patient";
import ErrorsService from "core/services/errors";
import FetcherService from "core/services/fetcher";
import { SemeiaMedicSession } from "core/services/session";
import { arrayEquals } from "core/utils/array";
import { sortedMedicsByHealthCentre } from "core/utils/medics-by-health-centre";
import { referentMedics } from "./utils";

/**
 * MedicForList is a model used by the view.
 * It uses properties to switch off tracking of properties, which we don't want here.
 * If tracked properties were kept, the whole list of medics would be redrawn each time
 * the referent state changed. This happened before and was occurring performance issues
 * in production where there are several hundred medics.
 */
export class MedicForList {
  id: string;
  fullName: string;
  role: string;
  @tracked isReferent = false;

  constructor(medic: Medic) {
    this.id = medic.id;
    this.fullName = medic.fullName;
    this.role = medic.role;
  }
}

export interface MedicReferentsByHealthCentre {
  healthCentreName: string;
  medics: MedicForList[];
}

export interface PatientMedicsRouteModel {
  patient: Patient;
  medicsByHealthCentres: MedicReferentsByHealthCentre[];
}

export default class PatientMedicsRoute extends Route<PatientMedicsRouteModel> {
  @service declare router: RouterService;
  @service declare fetcher: FetcherService;
  @service declare errors: ErrorsService;
  @service declare session: SemeiaMedicSession;

  async model() {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;
    const medics = await this.fetcher.loadRecords("medic", {
      include: "health-centres",
      backgroundReload: false
    });

    return {
      patient,
      medicsByHealthCentres: this.sortedMedicByHealthCentreSelections(
        patient,
        medics,
        this.session.medic
      )
    };
  }

  private abortConfirmed = false;

  @action
  willTransition(transition: Transition) {
    if (!this.abortConfirmed) this.checkMedicsIsDirty(transition);
  }

  private checkMedicsIsDirty(transition: Transition) {
    const controller = this.controllerFor(
      "medics-web.protected.patients.patient.medics"
    );
    const model = controller.model as PatientMedicsRouteModel;

    const patientMedicIDs = model.patient.medics.map(medic => medic.id);
    const selectedMedicIDs = referentMedics(model.medicsByHealthCentres).map(
      medic => medic.id
    );

    if (
      !arrayEquals(
        patientMedicIDs,
        Array.from([...new Set(selectedMedicIDs)] as string[])
      )
    ) {
      transition.abort();
      this.abortConfirmed = true;

      this.errors.showPendingChangesetError().then(response => {
        if (response.value) {
          this.router.transitionTo(
            "medics-web.protected.patients.patient.administrative"
          );
        }
      });
    }
  }

  /**
   * A list of Medics and their "referent" state for this patient.
   */
  private sortedMedicByHealthCentreSelections(
    patient: Patient,
    medics: Medic[],
    currentMedic: Medic
  ): MedicReferentsByHealthCentre[] {
    return sortedMedicsByHealthCentre(medics, currentMedic).map(medicByHc => {
      return {
        healthCentreName: medicByHc.healthCentreName,
        medics: this.medicsToMedicForList(patient, medicByHc.medics)
      };
    });
  }

  private medicsToMedicForList(
    patient: Patient,
    medics: Medic[]
  ): MedicForList[] {
    const result = medics
      .filter(
        medic => !MedicModel.nonDisplayableRoles.includes((medic as Medic).role)
      )
      .map(m => new MedicForList(m));

    const referentIds = new Set(patient.medics.map(m => m.id));
    result.forEach(m => {
      m.isReferent = referentIds.has(m.id);
    });

    return result;
  }
}
