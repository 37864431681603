import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class ResultsTableRespiratoryRateRoute extends Route {
  @service declare fetcher: FetcherService;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    await this.fetcher.loadRecords("respiratory-rate-measured-journal-event", {
      filter: { patient_id: patient.id }
    });

    return patient;
  }
}
