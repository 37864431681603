import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import WrModalContent from 'ember-wiser/components/wr/modal/content';
import WrDivider from 'ember-wiser/components/wr/divider';
import ProSanteConnectImage from 'parog-web/components/pro-sante-connect-image';
import V2MedicLoginForm from 'parog-web/components/v2/medic-login-form';
export default RouteTemplate(template(`
  <WrModalContent @targetModal='modal-1' @title='Connexion soignant'>
    <V2MedicLoginForm />

    <div class='flex flex-col items-center py-4 gap-4 w-full'>
      <WrDivider>ou</WrDivider>
      <ProSanteConnectImage />
    </div>
  </WrModalContent>
`, {
    eval () {
        return eval(arguments[0]);
    }
}));
