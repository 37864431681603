import * as Sentry from "@sentry/ember";

export interface SentryUser {
  id: string;
  uuid: string;
  role?: string;
}

export function setupSentry(): void;
export function setupSentry(user: SentryUser, klass: "Medic" | "Patient"): void;
export function setupSentry(
  user?: SentryUser,
  klass?: "Medic" | "Patient"
): void {
  const scope = Sentry.getCurrentScope();
  if (user) {
    const sentryUser: SentryUser & { class: "Medic" | "Patient" | undefined } =
      {
        id: user.id,
        uuid: user.uuid,
        class: klass
      };
    if (user.role) {
      sentryUser.role = user.role;
    }

    scope.setUser(sentryUser);
  } else {
    scope.setUser(null);
  }
}
