import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import * as Sentry from "@sentry/ember";
import SurveyRequestedJournalEvent from "core/models/survey-requested-journal-event";
import { surveyRequestedTransitionToParams } from "parog-web/utils/survey-requested";

type Model = SurveyRequestedJournalEvent;

export default class PatientWebFormRequestedRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  async model(params) {
    const formId = params["form_requested_id"];

    await this.store
      .findRecord("survey-requested-journal-event", formId, {
        include: "survey-completed-journal-event"
      })
      .catch(e => {
        Sentry.captureException(e);
      });

    return this.store.peekRecord("survey-requested-journal-event", formId);
  }

  afterModel(je: Model) {
    const params = surveyRequestedTransitionToParams(
      je,
      "patient",
      "patient-web.login",
      "patient-web.protected.dashboard.index",
      "patient-web.protected.form-requested"
    );

    this.router.transitionTo(...params);
  }
}
