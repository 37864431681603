import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type AbilitiesService from "ember-can/services/abilities";

export default class MedicsWebProtectedMedicsIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare store: Store;

  beforeModel() {
    if (this.abilities.cannot("list medic")) {
      this.router.transitionTo("medics-web.protected");
    }
  }

  model() {
    return this.store.peekAll("medic");
  }
}
