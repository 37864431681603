import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";

import Patient from "core/models/patient";

export default class ResultsTableDpCycleRoute extends Route {
  @service declare store: Store;

  async model() {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    await this.store.query("dp-cycle-declared-journal-event", {
      filter: { patient_id: patient.id }
    });

    return patient;
  }
}
