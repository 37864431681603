import Controller from "@ember/controller";
import Transition from "@ember/routing/transition";
import BaseFeatureComponentRoute from "core/components/forc/abstract/route/base-route-component";
import Patient from "core/models/patient";

export default class PatientFeatureComponentRoute extends BaseFeatureComponentRoute {
  patientModel(): Patient {
    return this.modelFor("patient-web.protected") as Patient;
  }

  setupController(controller: Controller, model: any, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.set(
      "surveyRequested",
      this.modelFor("patient-web.protected.form-requested")
    );
  }
}
