import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { SemeiaPatientSession } from "core/services/session";
import emberSwal from "ui-kit/utils/ember-swal";
export default class LogoutRoute extends Route {
  @service declare session: SemeiaPatientSession;
  @service declare router: RouterService;

  async beforeModel(): Promise<void> {
    emberSwal
      .fire({
        text: "Êtes vous sûr de vouloir vous déconnecter ?",
        icon: "error",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonText: "Oui, se déconnecter"
      })
      .then(async result => {
        if (result.isConfirmed) {
          await this.session.invalidate();
          this.router.transitionTo("patient-web.login");
        } else {
          this.router.transitionTo("patient-web.protected.dashboard.consult");
        }
      });
  }
}
