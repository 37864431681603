import Store from "@ember-data/store";
import ArrayProxy from "@ember/array/proxy";
import Route from "@ember/routing/route";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { ProtocolWithOriginJournalEvent } from "core/components/ok-chimio/table";
import TreatmentHistoryChangedJournalEvent from "core/models/treatment-history-changed-journal-event";
import type { SemeiaMedicSession } from "core/services/session";
import PatientListFilterSerializer, {
  JournalEventApiFilters
} from "core/utils/patient-list-filter-serializer";
import { getItemFromLocalStorage } from "core/utils/use-local-storage";
import Ember from "ember";
import OkChimioController from "./controller";

export default class OkChimioRoute extends Route {
  @service declare session: SemeiaMedicSession;
  @service declare store: Store;

  async model(): Promise<ProtocolWithOriginJournalEvent[]> {
    const treatments = await this._loadTreatmentJournalEvents();

    const protocols: ProtocolWithOriginJournalEvent[] = [];
    treatments.forEach(treatment => {
      treatment.okChimioMustBeValidated.forEach(protocol => {
        protocols.push({ protocol: protocol, originJournalEvent: treatment });
      });
    });

    return protocols;
  }

  /**
   * Load AlertTriggeredJournalEvent whose patient correspond to the filter of the Patient List page.
   */
  private async _loadTreatmentJournalEvents(): Promise<
    ArrayProxy<TreatmentHistoryChangedJournalEvent>
  > {
    return this.store.query(
      "treatment-history-changed-journal-event",
      this.journalEventOptions
    );
  }

  /**
   *  The journalEventOptions hash is isolated in a getter to simplify route unit tests
   */
  get journalEventOptions(): {
    include: string;
    filter: JournalEventApiFilters;
  } {
    return {
      filter: Object.assign(
        { validation_for_ok_chimio: "" },
        this.filter.journalEventsApiFilter
      ),
      include: [
        "patient.medics.health-centres",
        "patient.health-centre",
        "patient.medical-history-changed-journal-event",
        "patient.patient-enrolled-journal-event",
        "patient.bio-exam-results-received-journal-events",
        "patient.ctcae-filled-journal-events",
        "patient.pre-treatment-filled-journal-events",
        "patient.inter-treatment-filled-journal-events",
        "patient.ok-chimio-snapshot-captured-journal-events"
      ].join(",")
    };
  }

  /**
   * The filter is displayed in the route template, so we need to pass it to the controller.
   */
  setupController(
    controller: OkChimioController,
    model: TreatmentHistoryChangedJournalEvent[],
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.filter = this.filter;
  }

  // FIXME: the filter code should be shared with the one in the Patient List route.
  /**
   * This getter returns an instance of the class `PatientListFilterSerializer` which contains
   * the JE & patient API filters. These filters are set according to the filter contained in
   * the local storage, which is either the filter selected on medics-web homepage or the
   * `_defaultFilter`
   */
  get filter(): PatientListFilterSerializer {
    const filter =
      getItemFromLocalStorage(this._selectedFilterKey) || this._defaultFilter;

    return new PatientListFilterSerializer({
      serializedFilter: filter,
      store: this.store
    });
  }

  /**
   * This will be the filter value if no filter is found in the local storage
   */
  private get _defaultFilter(): string {
    return `fur%3Dall_medicId%3D${this.session.medic.id}_healthCentreId%3Dundefined_patientStatus%3Denrolled_taskType%3Dall_responsibleId%3Dall`;
  }

  /**
   * We get the selected filter from the local storage, because it is possible that
   * the user navigates to this route not directly from the `medics-web` route.
   * Maybe in the future, this page will have its own filters.
   */
  private get _selectedFilterKey(): string {
    const key = "medics-web.protected.updates.selectedFilter";
    if (Ember.testing) {
      return `test-${key}`;
    }
    return key;
  }
}
