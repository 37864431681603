import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import BaseFeatureComponentController from "core/components/forc/abstract/controller/base-controller-component";
import FormAction from "core/components/forc/form-action";
import NavigationService from "core/services/navigation";

export default class PatientWebFeatureComponentController extends BaseFeatureComponentController {
  @service declare router: RouterService;
  @service declare navigation: NavigationService;

  @action
  transitionToIndex() {
    if (this.callbackUrl) {
      this.router.transitionTo(this.callbackUrl);
    } else {
      this.router.transitionTo(
        `${this.navigation.currentPatientRouteName}.index`
      );
    }
  }

  get formAction() {
    return FormAction.New;
  }
}
