import Controller from "@ember/controller";
import Route from "@ember/routing/route";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import MedicModel from "core/models/medic";
import FetcherService from "core/services/fetcher";
import SemeiaSessionService from "core/services/session";

export default class MedicRoute extends Route {
  @service declare session: SemeiaSessionService;
  @service declare fetcher: FetcherService;

  async model(params: { medic_id: string }): Promise<MedicModel> {
    return await this.fetcher.loadRecord("medic", params.medic_id, {
      include: "health-centres"
    });
  }

  setupController(
    controller: Controller,
    model: MedicModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    // As the healthCentres cannot be edited, we just need to pass the medic healthCentres list
    controller.set(
      "healthCentres" as keyof Controller,
      this.session.medic?.healthCentres
    );
  }
}
