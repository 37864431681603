import { service } from "@ember/service";
import TaskPlannedJournalEvent from "core/models/task-planned-journal-event";
import FetcherService from "core/services/fetcher";
import dayjs from "dayjs";
import MedicsWebIndexTabRoute, {
  MedicsWebTabModel,
  MedicsWebTabParams
} from "../tab/route";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from "ember-data";

interface TasksAndConsultationsTabModel extends MedicsWebTabModel {
  tasks: DS.RecordArray<TaskPlannedJournalEvent>;
}
export default class MedicsWebIndexContentRoute extends MedicsWebIndexTabRoute {
  @service declare fetcher: FetcherService;

  get tabType() {
    return "tasks-and-consultations";
  }

  async model(
    params: MedicsWebTabParams
  ): Promise<TasksAndConsultationsTabModel> {
    const model = (await super.model(params)) as TasksAndConsultationsTabModel;

    const start = dayjs()
      .subtract(6, "w")
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    const end = dayjs().add(3, "d").endOf("day").format("YYYY-MM-DDTHH:mm:ss");

    // Tasks which responsible is the medic

    const filter = Object.assign(
      {},
      model.filter.journalEventsApiFilter
    ) as Record<string, string | undefined>;

    if (model.filter.parser.responsibleId !== "undefined") {
      filter.responsible_id = model.filter.parser.responsibleId;
      filter.responsible_type = "medic";
    } else {
      filter.no_responsible = "";
    }
    model.tasks = await this.store.query("task-planned-journal-event", {
      filter: Object.assign(Object.assign({}, filter), {
        task_type: model.filter.parser.taskType,
        occurred_at_gte: start,
        occurred_at_lte: end,
        processed: "false"
      }),
      include: "creator,patient.care-giver-added-journal-events"
    });

    await this.fetcher.loadRecords("referent-lab-updated-journal-event", {
      filter: model.filter.journalEventsApiFilter
    });

    return model;
  }
}
