import Route from "@ember/routing/route";
import Patient from "core/models/patient";
import type FeatureComponentEditionController from "./controller";

export default class PatientFeatureComponentRoute extends Route {
  model(params: { source_je_id: string }): {
    patient: Patient;
    componentName: string;
    sourceJeId: string;
  } {
    const model = this.modelFor("patient-web.protected.feature.component") as {
      patient: Patient;
      componentName: string;
      sourceJeId: string;
    };

    model.sourceJeId = params["source_je_id"];
    return model;
  }

  resetController(
    controller: FeatureComponentEditionController,
    isExiting: boolean
  ): void {
    if (isExiting) {
      controller.set("isReadOnly", false);
      controller.set("callbackUrl", undefined);
    }
  }
}
