import Store from "@ember-data/store";
import ModelRegistry from "@ember-data/types/registries/model";
import { getOwner } from "@ember/owner";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import * as Sentry from "@sentry/ember";
import ForcJournalEventBasedComponent from "core/components/forc/abstract/journal-event-based-component";
import Patient from "core/models/patient";
import type PatientFeatureComponentEditionController from "./controller";

export default class PatientFeatureComponentRoute extends Route {
  @service declare store: Store;

  myRoute = "medics-web.protected.patients.patient.feature.component";

  async model(params: { source_je_id: string }): Promise<{
    patient: Patient;
    componentName: string;
    sourceJeId: string;
  }> {
    const model = this.modelFor(this.myRoute) as {
      patient: Patient;
      componentName: string;
      sourceJeId: string;
    };
    model.sourceJeId = params["source_je_id"];

    await this.fetchEditedJeIfNeeded(model.componentName, model.sourceJeId);

    return model;
  }

  resetController(
    controller: PatientFeatureComponentEditionController,
    isExiting: boolean
  ): void {
    if (isExiting) {
      controller.set("isReadOnly", false);
      controller.set("callbackUrl", undefined);
    }
  }

  /**
   * Will fetch the required JE if it is not in the store yet
   */
  private async fetchEditedJeIfNeeded(
    componentName: string,
    recordId: string
  ): Promise<void> {
    // Do not fetch a JE if the component does not contains a 'journalEventClass' static property
    const modelName = this.retrieveModelName(componentName);
    if (!modelName) return;

    // Fetch the record if it is not in the store yet
    const opts = this.specificResourcesToInclude.has(modelName)
      ? { include: this.specificResourcesToInclude.get(modelName) }
      : {};
    const record = await this.store.findRecord(modelName, recordId, opts);

    // Throw an error if the record does not exist
    if (!record) {
      const e = new Error(
        `No record of type '${modelName}' with the id '${recordId}' found on the form '${componentName}'`
      );
      Sentry.captureException(e);
      throw e;
    }
  }

  /**
   * Compute the model name associated to a FORC component
   * Returns undefined if the component is not a FORC
   */
  private retrieveModelName(
    componentName: string
  ): keyof ModelRegistry | undefined {
    const owner = getOwner(this);
    const componentFactory = owner?.factoryFor(`component:${componentName}`);
    return (
      componentFactory?.class as unknown as ForcJournalEventBasedComponent
    ).journalEventClass?.modelName;
  }

  private specificResourcesToInclude: Map<keyof ModelRegistry, string> =
    new Map([
      ["task-planned-journal-event", "task-processed-journal-event"],
      ["alert-triggered-journal-event", "alert-processed-journal-event"]
    ]);
}
