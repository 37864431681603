import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";

export default class ResultsTableAdverseEventRoute extends Route {
  @service declare store: Store;

  async model() {
    return this.modelFor("medics-web.protected.patients.patient") as Patient;
  }
}
