import { getOwner } from "@ember/-internals/owner";
import { Timer, cancel } from "@ember/runloop";
import { service } from "@ember/service";
import SemeiaSession from "core/services/session";
import Base from "ember-simple-auth/authenticators/base";
import fetch from "fetch";
import ApplicationAdapter from "parog-web/adapters/application";
import {
  callRefreshTokenEndpoint,
  isTokenValid,
  scheduleAccessTokenRefresh,
  updateSessionAccessToken
} from "./utils/refresh-access-tokens";
import { revokeToken } from "./utils/revoke-token";
import { SentryUser, setupSentry } from "./utils/sentry";

export interface AuthData extends SentryUser {
  id: string;
  uuid: string;
  role: string;
  as: string;
  access_token: string;
  refresh_token?: string;
  two_factor_auth_token?: string;
  browser_auth_token?: string;
  psc_id_token?: string;
}

export default abstract class BaseAuthenticator<
  T extends AuthData
> extends Base {
  @service declare session: SemeiaSession;

  abstract loginEndpoint;
  declare _refreshTokenTimeout?: Timer;
  applicationAdapter = getOwner(this)?.lookup(
    "adapter:application"
  ) as ApplicationAdapter;

  async restore(data: T): Promise<T> {
    if (data.refresh_token) {
      // Do not authenticate if refresh token is expired
      if (!isTokenValid(data.refresh_token)) {
        setupSentry();
        throw "Base Password Authenticator - Invalid refresh token";
      }

      // If access token is expired, refresh it
      if (!isTokenValid(data.access_token)) {
        const { response } = await callRefreshTokenEndpoint.call(
          this,
          data.refresh_token
        );
        data.access_token = response.jwt;

        updateSessionAccessToken.call(this, data.access_token);
      }

      // Otherwise, do nothing and schedule next access token refresh
      scheduleAccessTokenRefresh.call(this, data);
    }

    setupSentry(data, this.userClassName);
    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  abstract authenticate(args: unknown): Promise<T | undefined>;

  async invalidate(): Promise<void> {
    if (this.session.user?.refreshTokensEnabled) {
      revokeToken.call(this);
    }
    cancel(this._refreshTokenTimeout);
    delete this._refreshTokenTimeout;
    setupSentry();
  }

  protected get userClassName(): "Medic" | "Patient" {
    return this.session.patient ? "Patient" : "Medic";
  }

  protected async sendLoginRequest(authData: any): Promise<AuthData> {
    const body = JSON.stringify({
      auth: authData
    });

    return await fetch(this.loginEndpoint, {
      method: "POST",
      headers: Object.assign(
        {
          "Content-Type": "application/json"
        },
        this.applicationAdapter.headers
      ),
      body: body
    }).then(async response => {
      if (!response.ok) {
        throw response;
      }
      return await response.json();
    });
  }

  protected async onAuthenticationFinished(
    data: T | undefined,
    klass: "Medic" | "Patient"
  ): Promise<T | undefined> {
    if (data) {
      setupSentry(data, klass);
    } else {
      setupSentry();
    }

    this.session.authenticatedAtThisSession = true;
    if (data !== undefined) {
      await this.session.fetchAuthenticatedUser(data);
    }
    if (this.session.user?.refreshTokensEnabled) {
      scheduleAccessTokenRefresh.call(this, data);
    }
    return data;
  }
}
