import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import CompoundSurveyCompletedJournalEvent from "compound-survey/models/compound-survey-completed-journal-event";

import Patient from "core/models/patient";
import NavigationService from "core/services/navigation";

export interface CompoundSurveyCompletedAndPatient {
  patient: Patient;
  surveyCompletedJe: CompoundSurveyCompletedJournalEvent;
}

export default class CompoundSurveysResultsRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare navigation: NavigationService;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    await this.store.query("compound-survey-completed-journal-event", {
      filter: { patient_id: patient.id }
    });

    return patient;
  }
}
