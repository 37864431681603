import Controller from "@ember/controller";
import { service } from "@ember/service";
import ParogService from "core/services/parog";
import { isProduction } from "core/utils/runtime-environment";

export default class PatientWebLoginController extends Controller {
  @service declare parog: ParogService;

  get showLoginAsLinks() {
    return !isProduction();
  }

  get authenticationUrls() {
    const magicLinkDeduppers = ["nw", "ow", "mw", "rw"];

    return magicLinkDeduppers.map(e => ({
      url: `/s/Patient0${e}`,
      email: `patient-zero+${e}@semeia.io`
    }));
  }
}
