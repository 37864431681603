/* eslint-disable ember/no-get */
import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

export default class SetPasswordController extends Controller {
  @service declare router: RouterService;

  queryParams = ["token"];
  token: string | undefined;

  @action
  onSuccess() {
    this.router.transitionTo("patient-web.login-with-account");
  }

  @action
  onFail() {
    this.router.transitionTo("patient-web.forgot-password.send-instructions");
  }
}
