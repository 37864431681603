import Route from "@ember/routing/route";
import Patient from "core/models/patient";
import MedicsWebProtectedPatientsPatientConsultationReportFormController from "./controller";

export default class MedicsWebProtectedPatientsPatientConsultationReportFormRoute extends Route<Patient> {
  model(): Patient {
    return this.modelFor("medics-web.protected.patients.patient") as Patient;
  }

  resetController(
    controller: MedicsWebProtectedPatientsPatientConsultationReportFormController,
    isExiting: boolean
  ): void {
    if (isExiting) {
      controller.set("consultationType", undefined);
    }
  }
}
