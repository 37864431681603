import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import ParogService from "core/services/parog";

export default class PatientModalFeatureController extends Controller {
  @service router;
  @service declare parog: ParogService;

  @action
  transitionToDashboard(): void {
    this.router.transitionTo(
      "medics-web.protected.patients.patient.dashboard.main"
    );
  }
}
