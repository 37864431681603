import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";

export default class BioResultsSummaryRoute extends Route {
  @service declare store: Store;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    await Promise.all([
      this.store.query("bio-exam-results-received-journal-event", {
        filter: { patient_id: patient.id },
        include: [
          "creator",
          "file-attachments",
          "apicrypt-message-received-journal-events"
        ].join(",")
      }),
      this.store.query("loinc", {
        filter: { patient_id: patient.id }
      }),
      this.store.query("bio-exam-expected-dosage-changed-journal-event", {
        filter: { patient_id: patient.id }
      })
    ]);

    return patient;
  }
}
