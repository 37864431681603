import Controller from "@ember/controller";
import { service } from "@ember/service";
import SemeiaSessionService from "core/services/session";
import {
  MedicsByHealthCentre,
  sortedMedicsByHealthCentre
} from "core/utils/medics-by-health-centre";
import Medic from "core/models/medic";

export default class MedicsWebProtectedMedicsIndexController extends Controller {
  @service router;
  @service declare session: SemeiaSessionService;

  get medics(): Medic[] {
    return this.model as Medic[];
  }

  get displayableMedics(): Medic[] {
    return (this.model as Medic[]).filter(
      medic => !Medic.nonDisplayableRoles.includes(medic.role)
    );
  }

  get sortMedicsByHealthCentre(): MedicsByHealthCentre[] {
    return sortedMedicsByHealthCentre(
      this.displayableMedics,
      this.session.medic
    );
  }
}
