import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import BioExamPrescribedJournalEvent from "core/models/bio-exam-prescribed-journal-event";
import BioExamReportTemplate from "core/models/bio-exam-report-template";
import Patient from "core/models/patient";
import { Changeset, EmberChangeset } from "ember-changeset";
import lookupValidator from "ember-changeset-validations";
import { validatePresence } from "ember-changeset-validations/validators";
import BioPrescriptionEditionFormController from "./controller";

export default class BioPrescriptionEditionFormRoute extends Route {
  @service declare store: Store;
  @tracked creationFromTemplate = true;
  oldBioExamPrescribed: BioExamPrescribedJournalEvent | undefined;

  queryParams = {
    id: {
      refreshModel: true
    }
  };

  get validations() {
    return {
      validityPeriod: validatePresence({
        presence: true,
        description: "La durée de validité"
      })
    };
  }

  setupController(
    controller: BioPrescriptionEditionFormController,
    model: any,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.set("oldBioExamPrescribed", this.oldBioExamPrescribed);
  }

  model(params) {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    // Create new BioExamPrescribedJournalEvent record
    const model = this.store.createRecord("bio-exam-prescribed-journal-event", {
      occurredAt: new Date(),
      validityPeriod: 6,
      patient: patient,
      createExpirationAlert: true
    });

    const prescriptionConfiguration = this.store
      .peekAll("prescription-configuration")
      .find(conf => conf.healthCentre.id == patient.healthCentre.id);

    // Update some properties if there's a configuration
    if (prescriptionConfiguration) {
      model.aldRelatedComments =
        prescriptionConfiguration.defaultBiologyPrescriptionAldComment as string;
    }

    const modelType = params["type"];
    const modelId = params["id"];

    if (!modelType && !modelId) {
      this.creationFromTemplate = false;
    }

    // If the selected template is a BioExamReportTemplate, set some arguments to the model
    if (modelType == "report-template") {
      const reportTemplateRecord = this.store.peekRecord(
        "bio-exam-report-template",
        modelId
      ) as BioExamReportTemplate;

      // Set exams array
      model.exams = reportTemplateRecord.bioExamOrders.map(
        order => order.loinc
      );
      // Set frequency
      model.frequency =
        reportTemplateRecord.frequency as BioExamPrescribedJournalEvent["frequency"];
    }

    // If the selected template is a BioExamPrescribedJe, set some arguments to the model
    if (modelType == "old-prescription") {
      this.oldBioExamPrescribed = this.store.peekRecord(
        "bio-exam-prescribed-journal-event",
        modelId
      ) as BioExamPrescribedJournalEvent;

      // Set the exams array, validity period, custom orders, comments & frequency
      model.exams = this.oldBioExamPrescribed.exams;
      model.validityPeriod = this.oldBioExamPrescribed.validityPeriod;
      model.customOrders = this.oldBioExamPrescribed.customOrders;
      model.comments = this.oldBioExamPrescribed.comments;
      model.aldRelatedComments = this.oldBioExamPrescribed.aldRelatedComments;
      model.frequency = this.oldBioExamPrescribed.frequency;
    }

    // Return the model encapsulated within a Changeset
    return Changeset(
      model,
      lookupValidator(this.validations),
      this.validations
    );
  }

  deactivate(): void {
    const model = (this.modelFor(this.routeName) as EmberChangeset)
      .data as BioExamPrescribedJournalEvent;
    if (model.isNew && !model.store.isDestroying) {
      model.deleteRecord();
    }
  }
}
