import { action } from "@ember/object";
import { service } from "@ember/service";
import BaseControllerComponent from "core/components/forc/abstract/controller/base-controller-component";

export default class PatientFeatureComponentController extends BaseControllerComponent {
  @service router;

  @action
  transitionToDashboard() {
    if (this.callbackUrl) {
      this.router.transitionTo(this.callbackUrl);
    } else {
      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard"
      );
    }
  }
}
