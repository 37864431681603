import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import SurveyRequestedJournalEvent from "core/models/survey-requested-journal-event";
import { surveyRequestedTransitionToParams } from "parog-web/utils/survey-requested";

interface Params extends Record<string, string> {
  form_requested_id: string;
}

type Model = SurveyRequestedJournalEvent;

export default class PatientFormRequestedRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;

  model({ form_requested_id: id }: Params) {
    return this.store.findRecord("survey-requested-journal-event", id);
  }

  afterModel(je: Model) {
    const [route, ...models] = surveyRequestedTransitionToParams(
      je,
      "medic",
      "medics-web.protected",
      "medics-web.protected.patients.patient.dashboard",
      "medics-web.protected.patients.patient.form-requested"
    );

    this.router.transitionTo(route, ...models);
  }
}
