import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import FetcherService from "core/services/fetcher";
import type { SemeiaMedicSession } from "core/services/session";

export default class BioExamsRoute extends Route {
  @service declare fetcher: FetcherService;
  @service declare session: SemeiaMedicSession;
  @service declare router: RouterService;

  beforeModel(): void {
    if (!this.session.medic.isSuperUser) {
      this.router.transitionTo(
        "medics-web.protected.index.alerts-and-notifications"
      );
    }
  }

  afterModel(): Promise<unknown> {
    return Promise.all([
      this.fetcher.loadRecords("loinc", {
        filter: { used_in_wise: "" },
        include: "external-flux-loinc-mappings",
        backgroundReload: false
      })
    ]);
  }
}
