import Route from "@ember/routing/route";
import { service } from "@ember/service";
import BioExamResultsReceivedJournalEvent from "core/models/bio-exam-results-received-journal-event";
import FetcherService from "core/services/fetcher";
import SemeiaSessionService from "core/services/session";

export default class BioExamsReviewRoute extends Route {
  @service declare session: SemeiaSessionService;
  @service declare fetcher: FetcherService;

  async model(params: {
    je_id: string;
  }): Promise<BioExamResultsReceivedJournalEvent | null> {
    const je = await this.fetcher.loadRecord(
      "bio-exam-results-received-journal-event",
      params.je_id,
      {
        include: [
          "patient",
          "file-attachments",
          "apicrypt-message-received-journal-events"
        ].join(","),
        backgroundReload: false
      }
    );

    if (je.lastApicryptMessage?.hprimPatient?.birthday) {
      await this.fetcher.loadRecords("patient", {
        filter: { birthday: je.lastApicryptMessage?.hprimPatient?.birthday },
        backgroundReload: false
      });
    }

    return je;
  }
}
