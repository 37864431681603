import Store from "@ember-data/store";
import Controller from "@ember/controller";
import Route from "@ember/routing/route";
import { RouteInfoWithAttributes } from "@ember/routing/route-info";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import { SemeiaMedicSession } from "core/services/session";
import { getItemFromLocalStorage } from "core/utils/use-local-storage";
import type AbilitiesService from "ember-can/services/abilities";
import PatientController from "parog-web/medics-web/protected/patients/patient/controller";

export default class PatientEditRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare session: SemeiaMedicSession;
  @service declare store: Store;

  transitionFromRoute = "medics-web.protected.patients.patient.dashboard";

  beforeModel(transition: Transition) {
    if (this.abilities.cannot("edit patient")) {
      this.router.transitionTo("medics-web.protected");
      return;
    }

    // FIXME: transitionFromRoute failing when origin route is a FORC
    const originRoute = transition.from as RouteInfoWithAttributes;
    if (originRoute && !Object.keys(originRoute.params as object).length) {
      this.transitionFromRoute = originRoute.name;
    }
  }

  setupController(
    controller: Controller,
    model: Patient,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
    controller.set("transitionFromRoute", this.transitionFromRoute);
  }

  // Used to validate INS requirements.
  get enableINSAccessTracking() {
    return getItemFromLocalStorage("enable-ins-access-tracking") == "true";
  }

  activate(transition: Transition) {
    super.activate(transition);

    if (this.enableINSAccessTracking) {
      const insAccessed = this.store.createRecord(
        "ins-accessed-journal-event",
        {
          patient: (
            this.controllerFor(
              "medics-web.protected.patients.patient"
            ) as PatientController
          ).model,
          occurredAt: new Date(),
          creator: this.session.medic
        }
      );
      // Save is a Promise, but that's not an issue here if we don't have for it.
      // This is just for tracking.
      insAccessed.save();
    }
  }
}
