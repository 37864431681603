import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import ConfigurationService from "core/services/configuration";
import FetcherService from "core/services/fetcher";

type FindPatientModel = {
  ippNumber: string;
  healthCentreFiness: string;
  healthCentreService: string;
  patient: Patient | undefined;
};
export default class FindPatientRoute extends Route {
  @service declare fetcher: FetcherService;
  @service declare router: RouterService;
  @service declare configuration: ConfigurationService;

  async model({
    concatenated_ipp
  }: {
    concatenated_ipp: string;
  }): Promise<FindPatientModel> {
    const [health_centre_finess, health_centre_service, ipp_number] =
      concatenated_ipp.split("_");

    const patient = (
      await this.fetcher.loadRecords("patient", {
        filter: {
          health_centre_finess,
          health_centre_service,
          ipp_number
        },
        include: "health-centre"
      })
    )[0];

    if (patient) {
      // TODO this two fetches are probably obsolete, to be updated once we actually use this feature.
      const orderReq = this.fetcher.loadRecords("bio-exam-order", {
        filter: { patient_id: patient.id }
      });

      const configReq =
        this.configuration.fetchConfigurationsForPatient(patient);

      await Promise.all([orderReq, configReq]);
    }

    return {
      patient,
      ippNumber: ipp_number,
      healthCentreFiness: health_centre_finess,
      healthCentreService: health_centre_service
    };
  }

  afterModel(model: FindPatientModel): void {
    if (model.patient) {
      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard",
        model.patient
      );
    }
  }
}
