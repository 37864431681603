import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import WrModalContent from 'ember-wiser/components/wr/modal/content';
import WrDivider from 'ember-wiser/components/wr/divider';
import WrButton from 'ember-wiser/components/wr/button';
import WrInputControl from 'ember-wiser/components/wr/form/controls/input';
import WrLinkTo from 'ember-wiser/components/wr/link-to';
export default RouteTemplate(template(`
  <WrModalContent @targetModal='modal-1' @title='Connexion patient'>
    <form class='flex flex-col items-center py-4 gap-4 w-full'>
      <h3 class='wr primary header text-center'>
        Créer/réinitialiser
        <br />
        votre mot de passe
      </h3>

      <WrInputControl
        aria-label='Votre adresse e-mail'
        type='text'
        placeholder='Votre adresse e-mail'
        class='max-w-[355px] my-2'
      />

      <WrButton type='submit' @icon='ArrowRight'>
        Recevoir l'e-mail
      </WrButton>

      <p class='text-center w-2/3'>
        Nous allons vous envoyer un email contenant un lien qui vous permettra
        de créer votre mot de passe. Si vous ne le recevez pas, pensez à
        vérifier vos spams.
      </p>
    </form>

    <div class='flex flex-col items-center py-4 gap-4 w-full'>
      <WrDivider>ou</WrDivider>

      <WrLinkTo @route='v2.patient-link-login'>
        Je reçois un lien de connexion
      </WrLinkTo>
    </div>
  </WrModalContent>
`, {
    eval () {
        return eval(arguments[0]);
    }
}));
