/* eslint-disable ember/no-get */
import Store from "@ember-data/store";
import { action, get } from "@ember/object";
import Route from "@ember/routing/route";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import MedicModel from "core/models/medic";
import ConfigurationService from "core/services/configuration";
import ErrorsService from "core/services/errors";
import SemeiaSessionService from "core/services/session";
import ProfileController from "./controller";

export default class ProfileRoute extends Route {
  @service declare session: SemeiaSessionService;
  @service declare configuration: ConfigurationService;
  @service declare store: Store;
  @service declare errors: ErrorsService;

  async model(): Promise<MedicModel> {
    return this.session.medic as MedicModel;
  }

  setupController(
    controller: ProfileController,
    model: MedicModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.set("healthCentres", model.healthCentres);
  }

  @action
  willTransition(transition: Transition): void {
    if (!get(this, "controller.changeset.isDirty" as keyof this)) return;
    transition.abort();

    this.errors.showPendingChangesetError();
  }
}
