import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import ParogService from "core/services/parog";

export default class NewPatientController extends Controller {
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare parog: ParogService;

  get patient() {
    return this.model as Patient;
  }

  get patientCreationNextStepFormId() {
    return this.parog.conf.patientCreationNextStepFormId;
  }

  get patientCreationOptionalNextStepFormId() {
    return this.parog.conf.patientCreationOptionalNextStepFormId;
  }

  @action
  transitionToPatientIndex() {
    this.router.transitionTo("medics-web.protected.patients.index");
  }

  get submitText() {
    return this.patientCreationNextStepFormId
      ? "Créer le patient et poursuivre"
      : "Créer ce patient";
  }

  @action
  transitionToNextRoute() {
    let nextRoute;

    if (this.patientCreationNextStepFormId) {
      // A Form is expected as second step. The frontend is responsible for creating the SurveyRequestedJE.
      const record = this.store.createRecord("survey-requested-journal-event", {
        occurredAt: new Date(),
        patient: this.patient,
        formId: this.patientCreationNextStepFormId,
        visibleOnlyBy: "medics"
      });

      record.save().then(je => {
        this.router.transitionTo(
          "medics-web.protected.patients.patient.form-requested",
          this.patient.id,
          je.get("id")
        );
      });
    } else if (this.patientCreationOptionalNextStepFormId) {
      this.router.transitionTo(
        "medics-web.protected.patients.patient.form",
        this.patient.id,
        this.patientCreationOptionalNextStepFormId
      );
    } else {
      nextRoute = this.router.urlFor(
        "medics-web.protected.patients.patient.dashboard",
        this.patient.id
      );
    }

    // If it was possible to determine the route synchronously, transition to it directly.
    // If nextRoute is not defined, it means that the transition will be done in an async `then` block
    if (nextRoute) {
      this.router.transitionTo(nextRoute);
    }
  }
}
