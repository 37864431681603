import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import BioExamResultsReceivedJournalEvent from "core/models/bio-exam-results-received-journal-event";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import { DS } from "ember-data";

export default class BioExamsSummaryRoute extends Route {
  @service declare store: Store;

  async model(): Promise<DS.RecordArray<BioExamResultsReceivedJournalEvent>> {
    return this.store.query("bio-exam-results-received-journal-event", {
      filter: { to_review: "" },
      include: [
        "patient",
        "patient.medics",
        "patient.health-centre",
        "file-attachments"
      ].join(",")
    });
  }
}
