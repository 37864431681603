import Route from "@ember/routing/route";
import { service } from "@ember/service";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";

export default class PatientWebProtectedConsultPrescriptionsRoute extends Route {
  @service declare fetcher: FetcherService;

  async model() {
    const patient = this.modelFor("patient-web.protected") as Patient;

    await this.fetchPrescriptionJes(patient);

    return patient;
  }

  async fetchPrescriptionJes(patient) {
    await Promise.all([
      this.fetcher.loadRecords("medication-prescribed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("bio-exam-prescribed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("etapes-prescribed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("medical-imaging-prescribed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("paramedical-care-prescribed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("tls-prescribed-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      }),
      this.fetcher.loadRecords("generic-prescription-uploaded-journal-event", {
        filter: {
          patient_id: patient.id
        },
        backgroundReload: false
      })
    ]);
  }
}
