import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import BioExamResultsReceivedJournalEvent from "core/models/bio-exam-results-received-journal-event";
import BioExamObservation from "core/models/loinc";
import trimLoincCode from "core/utils/trim-loinc-code";
import dayjs from "dayjs";
import { Changeset } from "ember-changeset";
import lookupValidator from "ember-changeset-validations";
import {
  validateDate,
  validatePresence
} from "ember-changeset-validations/validators";
import { ValidationResult } from "ember-changeset/types";

export default class BioResultsEditionController extends Controller {
  queryParams = ["sourceJeId"];

  get validations() {
    return {
      occurredAt: [
        validatePresence({
          presence: true,
          description:
            "La date de réalisation de vos analyses biologiques ne peut être vide"
        }),
        validateDate({
          message:
            "Cette donnée ne peut être déclarée dans le futur. Veuillez modifier la date",
          onOrBefore: dayjs().endOf("day").toDate()
        })
      ],
      nonZeroableResultsValidated: this.validateNonZeroableResults
    };
  }

  @action
  validateNonZeroableResults(
    _key: string,
    _accepted: unknown,
    _oldValue: unknown,
    changes: Record<string, string>,
    content: Record<string, string> & { store: Store }
  ): ValidationResult {
    let invalidResults = 0;
    // When there are changes in the changeset
    if (Object.keys(changes).length) {
      Object.keys(changes).forEach(change => {
        if (!change.startsWith("LOINC")) return;

        const obs = (content.store as Store).peekRecord(
          "loinc",
          trimLoincCode(change)
        ) as BioExamObservation;

        if (obs.zeroable) return;

        if (parseFloat(changes[change]) != 0.0) return;

        invalidResults += 1;
      });
    }

    if (invalidResults == 0) return true;

    return false;
  }

  get patient() {
    return (this.model as BioExamResultsReceivedJournalEvent).patient;
  }

  get hasRestrictedBioForm() {
    return this.patient.healthCentre.disableBioForPatient;
  }

  get changeset() {
    // Return the model encapsulated within a Changeset
    return Changeset(
      this.model as BioExamResultsReceivedJournalEvent,
      lookupValidator(this.validations),
      this.validations
    );
  }

  get isNew() {
    return this.changeset.isNew;
  }
}
