/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Store from "@ember-data/store";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type FollowUpReason from "core/models/follow-up-reason";
import validateFrenchPhoneNumber from "core/validators/french-phone-number";
import validateSometimes from "core/validators/sometimes";
import { EmberChangeset } from "ember-changeset";
import {
  validateFormat,
  validatePresence
} from "ember-changeset-validations/validators";
import { proIrcValidations } from "pro-irc/components/pro-irc/patient-inclusion";

export default class PatientInclusionController extends Controller {
  @service declare store: Store;

  validations = {
    followUpReason: validatePresence({
      presence: true,
      message: "Merci de préciser l'objet du suivi"
    }),
    enrollmentCriteriasValidated: this.validateEnrollmentCriterias,
    phone: validateSometimes(
      [
        validatePresence({
          presence: true,
          message: "Le numéro de téléphone ne peux être vide"
        }),
        validateFrenchPhoneNumber({
          message: "Le numéro de téléphone n'est pas valide"
        })
      ],
      function (this: EmberChangeset) {
        return (this as EmberChangeset)
          .get("contactPreferences")
          .includes("phone");
      }
    ),
    email: validateSometimes(
      [
        validatePresence({
          presence: true,
          message: "L'email ne peux être vide"
        }),
        validateFormat({
          type: "email",
          message: "Saisissez une adresse email valide"
        })
      ],
      function (this: EmberChangeset) {
        return (this as EmberChangeset)
          .get("contactPreferences")
          .includes("email");
      }
    ),
    contactPreferences: validateSometimes(
      validatePresence({
        presence: true,
        message: "Veuillez séléctionner au moins un moyen de contact"
      }),
      function (this: EmberChangeset) {
        return (this as EmberChangeset).get("appUsage").agreed;
      }
    ),
    ...proIrcValidations
  };

  @action
  validateEnrollmentCriterias(
    _key,
    accepted: boolean,
    _oldValue,
    changes: { followUpReason?: FollowUpReason["id"] },
    _content
  ): boolean | string {
    if (changes.followUpReason != "mental-bipolar") return true;
    if (accepted === true) return true;

    return "Merci de confirmer que les critères d'inclusion ont été validés";
  }
}
