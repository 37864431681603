import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import { service } from "@ember/service";

import Patient from "core/models/patient";

export default class BioResultsEditionRoute extends Route {
  @service declare store: Store;

  queryParams = {
    sourceJeId: {
      refreshModel: true
    }
  };

  async model(params) {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    const sourceJeId = params["sourceJeId"];

    if (!sourceJeId) {
      const model = this.store.createRecord(
        "bio-exam-results-received-journal-event",
        {
          occurredAt: new Date(),
          patient: patient,
          results: []
        }
      );
      return model;
    }

    const model = await this.store.findRecord(
      "bio-exam-results-received-journal-event",
      sourceJeId,
      {
        include: [
          "creator",
          "file-attachments",
          "apicrypt-message-received-journal-events"
        ].join(","),
        reload: true
      }
    );

    model.results?.forEach(({ code, value }) => {
      if (!code.startsWith("ADHOC")) {
        model[code] = value;
      }
    });

    return model;
  }
}
