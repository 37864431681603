import Controller from "@ember/controller";
import { service } from "@ember/service";
import BioExamResultsService from "core/services/bio-exam-results";
import type ParogService from "core/services/parog";
import type { SemeiaMedicSession } from "core/services/session";

export default class MedicsWebController extends Controller {
  @service declare session: SemeiaMedicSession;
  @service declare parog: ParogService;
  @service declare bioExamResults: BioExamResultsService;
}
