import { possibleTypes } from "core/components/task/utils/get-task-types";
import MedicModel from "core/models/medic";
import MedicsWebTabController from "../tab/controller";

export default class MedicsWebTasksAndConsultationsController extends MedicsWebTabController {
  get taskTypes(): string[] {
    return ["all", ...possibleTypes(undefined, this.session.medic)];
  }
  get responsibles(): MedicModel[] {
    return this.medics;
  }
}
