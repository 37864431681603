import Store from "@ember-data/store";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { loadVitalSigns } from "core/data/vital-signs";
import CableService from "core/services/cable";
import FetcherService from "core/services/fetcher";
import { SemeiaMedicSession } from "core/services/session";
import TermsOfUseService from "core/services/terms-of-use";
import emberSwal from "ui-kit/utils/ember-swal";

export default class MedicsWebProtectedRoute extends Route {
  @service declare termsOfUse: TermsOfUseService;
  @service declare router: RouterService;
  @service declare session: SemeiaMedicSession;
  @service declare cable: CableService;
  @service declare store: Store;
  @service declare fetcher: FetcherService;

  async loadMedics() {
    // Medics are used to display referent medics, or task responsible.
    await this.fetcher.loadRecords("medic", {
      include: "health-centres"
    });
  }

  async beforeModel(transition: Transition): Promise<void> {
    if (this.session.requireAuthentication(transition, "medics-web.login")) {
      // FIXME: now that we have our own session service, this logic could be moved in the service
      if (!this.session.medic) {
        this.router.transitionTo("medics-web.login");
      }

      const unauthorizedRoles = ["waiting", "nothing"];
      if (unauthorizedRoles.includes(this.session.medic.role)) {
        await emberSwal.fire(
          "Validation du compte",
          "Votre compte est en cours de vérification par nos équipes. Nous vous contacterons quand il aura été validé. Merci pour votre patience.",
          "warning"
        );
        await this.session.invalidate();
      }

      this.termsOfUse.redirectToAcceptTermsOfUseIfNeeded(
        this.session.medic?.acceptedTermsOfUseVersion,
        transition
      );

      loadVitalSigns();
      await this.loadMedics();
      await this.cable.openSocket();
    }
  }

  deactivate() {
    this.cable.closeSocket();
  }
}
