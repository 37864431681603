import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import NavigationService from "core/services/navigation";

export default class PatientWebIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare navigation: NavigationService;

  beforeModel() {
    this.router.transitionTo(
      `${this.navigation.currentPatientRouteName}.dashboard`
    );
  }
}
