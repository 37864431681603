import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

export default class BioPrescriptionTemplateSelectionController extends Controller {
  @service declare router: RouterService;

  @action
  transitionToDashboard(): void {
    this.router.transitionTo(
      "medics-web.protected.patients.patient.dashboard.main"
    );
  }
}
